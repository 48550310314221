import { Injectable, Injector } from '@angular/core';

import { ActionCd, DisputeStatusCd } from '@xpo-ltl/sdk-common';
import { Dispute, UpdateDisputeRqst, UpsertDisputeRqst } from '@xpo-ltl/sdk-disputes';

import { AuditCompanyInformationFormNamesEnum, DisputeDetailsFormNamesEnum } from '../../enums';

import { DisputesRegistrationService } from './disputes-registration.service';

@Injectable({
  providedIn: 'root',
})
export class OccDisputesRegistrationService extends DisputesRegistrationService {
  constructor(protected injector: Injector) {
    super(injector);
  }

  createUpsertDisputesReq(disputeStatus: DisputeStatusCd): UpsertDisputeRqst {
    const req: UpsertDisputeRqst = new UpsertDisputeRqst();
    req.dispute = this.createDispute();
    req.shipments = this.createShipmentForUpsert();
    req.asEnteredParty = this.createAsEnteredParty();
    req.employeeNotify = this.createEmployeeNotifyForUpsert();

    const onBehalfOf = this.createOnBehalfOf();
    const claimant = this.createClaimant();
    req.claimant = claimant;
    req.onBehalfOf = onBehalfOf;

    req.claimantContact = this.createClaimantContact();
    req.onBehalfOfContact = this.createOnBehalfOfContact();
    req.itemInstId = this.getItemInstId();
    req.disputeDocuments = this.createDisputeDocuments();
    return req;
  }

  createDispute(): Dispute {
    const dispute: Dispute = super.createDispute();
    const DisputeDetailsForm = this.disputeFormGroup.get(DisputeDetailsFormNamesEnum.FORM_GROUP_NAME);
    dispute.disputeId = this.dispute ? this.dispute.dispute.disputeId : undefined;
    dispute.requestReasonCd = DisputeDetailsForm.get(DisputeDetailsFormNamesEnum.REQUEST_REASON).value || undefined;
    dispute.description = DisputeDetailsForm.get(DisputeDetailsFormNamesEnum.REQUEST_EXPLANATION).value;
    dispute.statusCd = DisputeStatusCd.DRAFT;
    dispute.listActionCd = dispute.disputeId ? ActionCd.UPDATE : ActionCd.ADD;
    dispute.onBehalfOfSameAsClaimantInd = this.disputeFormGroup
      .get(AuditCompanyInformationFormNamesEnum.FORM_GROUP_NAME)
      .get(AuditCompanyInformationFormNamesEnum.SAME_AS).value;
    dispute.recordVersionNbr = this.dispute ? this.dispute.dispute.recordVersionNbr : undefined;
    return dispute;
  }

  createUpdateDisputeRqst(): UpdateDisputeRqst {
    const req = super.createUpdateDisputeRqst();
    req.asEnteredParties = this.createAsEnteredParty();
    req.onBehalfOf = this.createOnBehalfOf();
    req.claimant = this.createClaimant();
    req.claimantContact = this.createClaimantContact();
    req.onBehalfOfContact = this.createOnBehalfOfContact();
    return req;
  }
}
