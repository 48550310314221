<xpo-shell
  *ngIf="!isShipDetail"
  [routes]="routes$ | async"
  [drawerClosedByDefault]="isClosedByDefault"
  [versionNumber]="build"
  [isDrawerOpen]="isDrawerOpen$ | async"
  (isDrawerOpenChange)="isDrawerOpenChange($event)"
>
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>Disputes</xpo-header-sub-app-name>
  <xpo-header-actions>
    <div *ngIf="showFinishedLoading$ | async" data-test-loading="true"></div>
    <xpo-ltl-global-search class="xpoLtl-global-search" [globalSearchOptions]="globalSearchOptions"></xpo-ltl-global-search>
    <div *ngIf="!isProduction">
      <span (click)="showUserDetail()" data-test="loggedInUser">{{ currentUser$ | async }}</span>
    </div>
    <xpo-account-popover *ngIf="accountPopoverConfig" [config]="accountPopoverConfig"></xpo-account-popover>
    <xpo-app-switcher-popover [applications]="apps"></xpo-app-switcher-popover>
  </xpo-header-actions>
  <xpo-shell-sidebar-actions class="pnd-Root-sidebar-actions">
    <!-- add your action buttons here -->
  </xpo-shell-sidebar-actions>
  <xpo-shell-sidebar-actions class="pnd-Root-sidebar-actions">
    <!--
          <div *ngIf="feedbackService.feedbackConfig$ | async">
            <a (click)="handleFeedbackClick()">Feedback</a>
          </div>
        -->
        <div class="pnd-Root-sidebar-actions--feedback">
          <a
            href="https://xpologistics-my.sharepoint.com/:x:/g/personal/tiffany_kelley_xpo_com/EVaeeSwrKv9Bso_bWGcJjTAB5fcPlsBLrMylmNsJYaeJhg"
            target="_blank"
            >DOA - Disputes</a
          >
        </div>
        <div class="pnd-Root-sidebar-actions--feedback">
      <a [routerLink]="" id="jira-feedback" data-test="feedback">Feedback</a>
    </div>
    <div class="pnd-Root-sidebar-actions--feedback">
      <a
        href="https://xpologistics.sharepoint.com/sites/150/ttdd/OMS_Audit/SitePages/Disputes%20Release%20Notes.aspx"
        target="_blank"
        >Release Notes</a
      >
    </div>
  </xpo-shell-sidebar-actions>
  <router-outlet></router-outlet>
  <app-notification></app-notification>
</xpo-shell>

<router-outlet *ngIf="isShipDetail"></router-outlet>
