export enum DisputeRolesEnum {
  VIEW_ONLY = 'VIEW_ONLY',
  REQUESTER = 'REQUESTER',
  OCC_APPROVER = 'OCC_APPROVER',
  WNI_APPROVER = 'WNI_APPROVER',
  CORRECTIONS_AUDITOR = 'CORRECTIONS_AUDITOR',
  // #region DRP
  NAE = 'NAE',
  CUSTOMER_CARE_SUPERVISOR = 'CUSTOMER_CARE_SUPERVISOR',
  SERVICE_CENTER_MANAGER = 'SERVICE_CENTER_MANAGER',
  SMRS = 'SMRS',
  DNS = 'DNS',
  DOO = 'DOO',
  DIRECTOR_OF_CUSTOMER_CARE = 'DIRECTOR_OF_CUSTOMER_CARE',
  VPAS = 'VPAS',
  VPAO = 'VPAO',
  VP_MARKETING = 'VP_MARKETING',
  SVP_SALES = 'SVP_SALES',
  SVP_OPS = 'SVP_OPS',
  VP_FINANCE = 'VP_FINANCE',
  ADMIN = 'ADMIN',
  COLL_REVIEWER = 'COLL_REVIEWER',
  COLL_APPROVER = 'COLL_APPROVER',
  DISPUTE_DIRECTOR = 'DISPUTE_DIRECTOR',
  DISPUTE_VP = 'DISPUTE_VP',
  DISPUTE_SVP = 'DISPUTE_SVP',
  DISPUTE_VPS = 'DISPUTE_VPS',
  // #endregion
}
