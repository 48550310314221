import { Injectable } from '@angular/core';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';

import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { DisputeDrpCategoryCd, DisputeStatusCd, DisputeTypeCd, User } from '@xpo-ltl/sdk-common';
import { GetDisputeResp } from '@xpo-ltl/sdk-disputes';
import {
  Employee,
  GetEmployeeDetailsByEmpIdPath,
  GetEmployeeDetailsByEmpIdQuery,
  GetEmployeeDetailsByEmpIdResp,
  HumanResourceApiService,
} from '@xpo-ltl/sdk-humanresource';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DisputeEditModesEnum, DisputePermissionsEnum, DisputeRolesEnum } from '../../enums';
import { DisputeRolesEnumHelper } from '../../enums/users/dispute-roles-enum.helper';
import { UserDomain } from '../../enums/users/user-domain.enum';
import { DisputeStatusHelperService } from '../disputes/dispute-status-helper.service';
import { DisputesDataService } from '../disputes/disputes-data.service';
import { DisputesRolesPermissionsService } from './disputes-roles-permissions.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  protected unSubscriber = new Unsubscriber();

  constructor(
    protected humanResourceApiService: HumanResourceApiService,
    private disputesRolesPermissionsService: DisputesRolesPermissionsService,
    private disputesDataService: DisputesDataService,
    private configManagerService: ConfigManagerService
  ) {}
  // #region Domains
  private conwayRoles = DisputeRolesEnumHelper.values().map((each) => {
    return `${UserDomain.CONWAY}/${this.disputesRolesPermissionsService.getRoleFromConfig(each)}`;
  });
  private conwayAdRoles = DisputeRolesEnumHelper.values().map((each) => {
    return `${UserDomain.CONWAY_AD}/${this.disputesRolesPermissionsService.getRoleFromConfig(each)}`;
  });
  private ctsRoles = DisputeRolesEnumHelper.values().map((each) => {
    return `${UserDomain.CTS}/${this.disputesRolesPermissionsService.getRoleFromConfig(each)}`;
  });
  private ctsAdRoles = DisputeRolesEnumHelper.values().map((each) => {
    return `${UserDomain.CTS_AD}/${this.disputesRolesPermissionsService.getRoleFromConfig(each)}`;
  });
  private cfiRoles = DisputeRolesEnumHelper.values().map((each) => {
    return `${UserDomain.CFI}/${this.disputesRolesPermissionsService.getRoleFromConfig(each)}`;
  });
  private cfiAdRoles = DisputeRolesEnumHelper.values().map((each) => {
    return `${UserDomain.CFI_AD}/${this.disputesRolesPermissionsService.getRoleFromConfig(each)}`;
  });
  // #endregion

  // #region Current User

  currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
  currentUser$ = this.currentUserSubject.asObservable();

  get region(): string {
    return this.configManagerService.getSetting('sys_rgn');
  }

  get currentUser(): User {
    return this.currentUserSubject.value;
  }

  set currentUser(currentUser: User) {
    this.currentUserSubject.next(currentUser);
    this.currentRoles = this.getValidRoles(currentUser);
  }

  loggedInEmplDataSubject: BehaviorSubject<GetEmployeeDetailsByEmpIdResp> = new BehaviorSubject<
    GetEmployeeDetailsByEmpIdResp
  >(undefined);
  loggedInEmplData$ = this.loggedInEmplDataSubject.asObservable();

  get loggedInEmplData(): GetEmployeeDetailsByEmpIdResp {
    return this.loggedInEmplDataSubject.value;
  }

  set loggedInEmplData(getEmployeeDetailsByEmpIdResp: GetEmployeeDetailsByEmpIdResp) {
    this.loggedInEmplDataSubject.next(getEmployeeDetailsByEmpIdResp);
  }

  // #endregion

  // #region Current Role

  currentRolesSubject: BehaviorSubject<DisputeRolesEnum[]> = new BehaviorSubject<DisputeRolesEnum[]>(undefined);
  currentRoles$ = this.currentRolesSubject.asObservable();

  get currentRoles(): DisputeRolesEnum[] {
    return this.currentRolesSubject.value;
  }

  set currentRoles(currentRole: DisputeRolesEnum[]) {
    this.currentRolesSubject.next(currentRole);
    this.permissions = this.disputesRolesPermissionsService.getPermissionsByRoles(currentRole);
  }

  // #endregion

  // #region Permissions

  permissionsSubject: BehaviorSubject<DisputePermissionsEnum[]> = new BehaviorSubject<DisputePermissionsEnum[]>([]);
  permissions$ = this.permissionsSubject.asObservable();

  get permissions(): DisputePermissionsEnum[] {
    return this.permissionsSubject.value;
  }

  set permissions(values: DisputePermissionsEnum[]) {
    this.permissionsSubject.next(values);
  }

  hasRole(role: any[]): boolean {
    if (!role || !role.length) {
      return false;
    }
    const roles = <any>this.currentRoles;
    const roleExists = _.find(role, (eachRole) => {
      const roleName = this.disputesRolesPermissionsService.getRoleFromConfig(eachRole);
      return (
        roles.includes(`${UserDomain.CONWAY}/${roleName}`) ||
        roles.includes(`${UserDomain.CONWAY_AD}/${roleName}`) ||
        roles.includes(`${UserDomain.CTS}/${roleName}`) ||
        roles.includes(`${UserDomain.CTS_AD}/${roleName}`) ||
        roles.includes(`${UserDomain.CFI}/${roleName}`) ||
        roles.includes(`${UserDomain.CFI_AD}/${roleName}`)
      );
    });
    return roleExists;
  }

  getRoleIdByRoleName(roleName): number {
    if (!this.loggedInEmplData || !this.loggedInEmplData.employee || !this.loggedInEmplData.employee.roles) {
      return;
    }
    const roleId = _.find(this.loggedInEmplData.employee.roles, (currRole) => {
      return currRole.role.roleName.includes(roleName);
    }).role.roleId;

    return Number(roleId);
  }

  // #endregion

  clear(): void {
    this.currentUser = undefined;
    this.currentRoles = [];
    this.permissions = [];
  }

  getEmployee(employeeId: string) {
    const path = new GetEmployeeDetailsByEmpIdPath();
    path.employeeId = employeeId;
    const query = new GetEmployeeDetailsByEmpIdQuery();
    return this.humanResourceApiService.getEmployeeDetailsByEmpId(path, query).pipe(takeUntil(this.unSubscriber.done$));
  }

  getUserFullName(): string {
    if (!this.currentUser) {
      return '';
    }
    return `${this.currentUser.givenName} ${this.currentUser.lastName}`;
  }

  getEmployeeId(): string {
    if (!this.currentUser) {
      return '';
    }
    return this.currentUser.employeeId;
  }

  getEmployeeName(): string {
    if (!this.loggedInEmplData || !this.loggedInEmplData.employee || !this.loggedInEmplData.employee.basicInfo) {
      return '';
    }
    const firstName = this.loggedInEmplData.employee.basicInfo.firstName;
    const lastName = this.loggedInEmplData.employee.basicInfo.lastName;
    return `${firstName} ${lastName}`;
  }

  getRoleIdByEmployee(employee: Employee): number {
    if (!employee || !employee.roles) {
      return;
    }
    const roleId = Number(
      _.find(employee.roles, (eachRole) => {
        return eachRole.role.roleName.includes('Dispute') || eachRole.role.roleDescription.includes('Dispute');
      }).role.roleId
    );
    return roleId;
  }

  getCurrentUserRoleId(): number {
    return this.getRoleIdByEmployee(this.loggedInEmplData.employee);
  }

  getCurrentUserRole(): string {
    // TODO: Improve this depends on the dispute status
    return this.removeDomainFromRoleName(this.currentRoles[0]);
  }

  private removeDomainFromRoleName(roleNameWithDomain: string) {
    if (!roleNameWithDomain) {
      return;
    }
    const roleNameSplit = roleNameWithDomain.split('/');
    return roleNameSplit && roleNameSplit.length > 1 ? roleNameSplit[1] : roleNameSplit[0];
  }

  getRoleIdByEmployeeId(employeeId: string) {
    return new Observable<number>((observer) => {
      this.getEmployee(employeeId).subscribe((data: GetEmployeeDetailsByEmpIdResp) => {
        const roleId = this.getRoleIdByEmployee(data.employee);
        observer.next(roleId);
        observer.complete();
      });
    });
  }

  getUserRole(dispute: GetDisputeResp): string {
    if (!this.currentRoles || !this.currentRoles.length) {
      return;
    }
    let roleEnum = '';
    switch (dispute.dispute.typeCd) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        roleEnum = this.getOCCRoleEnum();
        break;
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        roleEnum = this.getDRPRoleEnum(dispute);
        break;
      case DisputeTypeCd.INSPECTION_APPEALS:
        roleEnum = this.getWNIRoleEnum();
        break;
      default:
        return;
    }
    if (!roleEnum) {
      throw Error('Error finding your role');
    }
    return this.disputesRolesPermissionsService.getRoleFromConfig(roleEnum);
  }

  private getOCCRoleEnum(onlyApprover = false): DisputeRolesEnum {
    if (this.hasRole([DisputeRolesEnum.OCC_APPROVER])) {
      return DisputeRolesEnum.OCC_APPROVER;
    }
    if (this.hasRole([DisputeRolesEnum.CORRECTIONS_AUDITOR])) {
      return DisputeRolesEnum.CORRECTIONS_AUDITOR;
    }
    if (!onlyApprover && this.hasRole([DisputeRolesEnum.REQUESTER])) {
      return DisputeRolesEnum.REQUESTER;
    }
    return;
  }

  private getWNIRoleEnum(onlyApprover = false): DisputeRolesEnum {
    if (this.hasRole([DisputeRolesEnum.WNI_APPROVER])) {
      return DisputeRolesEnum.WNI_APPROVER;
    }
    if (this.hasRole([DisputeRolesEnum.CORRECTIONS_AUDITOR])) {
      return DisputeRolesEnum.CORRECTIONS_AUDITOR;
    }
    if (!onlyApprover && this.hasRole([DisputeRolesEnum.REQUESTER])) {
      return DisputeRolesEnum.REQUESTER;
    }
    return;
  }

  private getDRPRoleEnum(dispute: GetDisputeResp, onlyApprover = false): DisputeRolesEnum {
    switch (dispute.dispute.drpCategoryCd) {
      case DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION:
        return this.getDrpCollectionEscalationRoleEnum(onlyApprover);
      case DisputeDrpCategoryCd.DRP_DEBTOR_CHANGE:
        return this.getDrpRegularOrDebtorChangeRoleEnum(onlyApprover);
      case DisputeDrpCategoryCd.DRP_REGULAR:
        return this.getDrpRegularOrDebtorChangeRoleEnum(onlyApprover);
    }
  }

  private getDrpCollectionEscalationRoleEnum(onlyApprover = false): DisputeRolesEnum {
    if (this.hasRole([DisputeRolesEnum.COLL_APPROVER])) {
      return DisputeRolesEnum.COLL_APPROVER;
    }
    if (this.hasRole([DisputeRolesEnum.COLL_REVIEWER])) {
      return DisputeRolesEnum.COLL_REVIEWER;
    }
    if (this.hasRole([DisputeRolesEnum.CORRECTIONS_AUDITOR])) {
      return DisputeRolesEnum.CORRECTIONS_AUDITOR;
    }
    if (!onlyApprover && this.hasRole([DisputeRolesEnum.REQUESTER])) {
      return DisputeRolesEnum.REQUESTER;
    }
    return;
  }

  private getDrpRegularOrDebtorChangeRoleEnum(onlyApprover = false): DisputeRolesEnum {
    // return vpFinance || svp || vp || director || custCareSupv || nae || svcCntrMngr || reqestorRoleName;
    if (this.hasRole([DisputeRolesEnum.ADMIN])) {
      return DisputeRolesEnum.ADMIN;
    }
    if (this.hasRole([DisputeRolesEnum.DISPUTE_VPS])) {
      return DisputeRolesEnum.DISPUTE_VPS;
    }
    if (this.hasRole([DisputeRolesEnum.VP_FINANCE])) {
      return DisputeRolesEnum.VP_FINANCE;
    }
    if (this.hasRole([DisputeRolesEnum.DISPUTE_SVP])) {
      return DisputeRolesEnum.DISPUTE_SVP;
    }
    if (this.hasRole([DisputeRolesEnum.DISPUTE_VP])) {
      return DisputeRolesEnum.DISPUTE_VP;
    }
    if (this.hasRole([DisputeRolesEnum.SVP_OPS])) {
      return DisputeRolesEnum.SVP_OPS;
    }
    if (this.hasRole([DisputeRolesEnum.SVP_SALES])) {
      return DisputeRolesEnum.SVP_SALES;
    }
    if (this.hasRole([DisputeRolesEnum.DISPUTE_DIRECTOR])) {
      return DisputeRolesEnum.DISPUTE_DIRECTOR;
    }
    if (this.hasRole([DisputeRolesEnum.DIRECTOR_OF_CUSTOMER_CARE])) {
      return DisputeRolesEnum.DIRECTOR_OF_CUSTOMER_CARE;
    }
    if (this.hasRole([DisputeRolesEnum.CUSTOMER_CARE_SUPERVISOR])) {
      return DisputeRolesEnum.CUSTOMER_CARE_SUPERVISOR;
    }
    if (this.hasRole([DisputeRolesEnum.NAE])) {
      return DisputeRolesEnum.NAE;
    }
    if (this.hasRole([DisputeRolesEnum.SERVICE_CENTER_MANAGER])) {
      return DisputeRolesEnum.SERVICE_CENTER_MANAGER;
    }
    if (this.hasRole([DisputeRolesEnum.CORRECTIONS_AUDITOR])) {
      return DisputeRolesEnum.CORRECTIONS_AUDITOR;
    }
    if (!onlyApprover && this.hasRole([DisputeRolesEnum.REQUESTER])) {
      return DisputeRolesEnum.REQUESTER;
    }
    return;
  }

  getRoleForSubmittedForReview(): string {
    let roleEnum = '';
    if (this.hasRole([DisputeRolesEnum.REQUESTER])) {
      roleEnum = DisputeRolesEnum.REQUESTER;
    } else if (this.hasRole([DisputeRolesEnum.COLL_REVIEWER])) {
      roleEnum = DisputeRolesEnum.COLL_REVIEWER;
    } else {
      throw Error('Error finding your role for submit for review.');
    }
    return this.disputesRolesPermissionsService.getRoleFromConfig(roleEnum);
  }

  getRequestorRole(): string {
    let roleEnum = '';
    if (this.hasRole([DisputeRolesEnum.REQUESTER])) {
      roleEnum = DisputeRolesEnum.REQUESTER;
    } else {
      throw Error('Error finding your requestor role.');
    }
    return this.disputesRolesPermissionsService.getRoleFromConfig(roleEnum);
  }

  getReviewerRole(): string {
    let roleEnum = '';
    if (this.hasRole([DisputeRolesEnum.COLL_REVIEWER])) {
      roleEnum = DisputeRolesEnum.COLL_REVIEWER;
    } else {
      throw Error('Error finding your reviewer role');
    }
    return this.disputesRolesPermissionsService.getRoleFromConfig(roleEnum);
  }

  getApproverRole(dispute): string {
    if (!this.loggedInEmplData || !this.loggedInEmplData.employee || !this.loggedInEmplData.employee.roles) {
      return;
    }
    let roleEnum = '';
    switch (dispute.dispute.typeCd) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        roleEnum = this.getOCCRoleEnum(true);
        break;
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        roleEnum = this.getDRPRoleEnum(dispute, true);
        break;
      case DisputeTypeCd.INSPECTION_APPEALS:
        roleEnum = this.getWNIRoleEnum();
        break;
      default:
        return;
    }
    if (!roleEnum) {
      throw Error('Error finding your approver role');
    }
    return this.disputesRolesPermissionsService.getRoleFromConfig(roleEnum);
  }

  getCorrectionsAuditorRole() {
    let roleEnum;
    if (this.hasRole([DisputeRolesEnum.CORRECTIONS_AUDITOR])) {
      roleEnum = DisputeRolesEnum.CORRECTIONS_AUDITOR;
    }
    return this.disputesRolesPermissionsService.getRoleFromConfig(roleEnum);
  }

  getValidRoles(currentUser: User): DisputeRolesEnum[] {
    if (!currentUser || !currentUser.roles) {
      return;
    }
    const currentRoles = [];
    for (let index = 0; index < currentUser.roles.length; index++) {
      const role = currentUser.roles[index] ? currentUser.roles[index].trim() : '';
      if (
        this.conwayRoles.includes(role) ||
        this.conwayAdRoles.includes(role) ||
        this.ctsRoles.includes(role) ||
        this.ctsAdRoles.includes(role) ||
        this.cfiRoles.includes(role) ||
        this.cfiAdRoles.includes(role)
      ) {
        currentRoles.push(role);
      }
    }
    return currentRoles;
  }

  private hasAnyPermission(permissions: DisputePermissionsEnum[]) {
    if (!permissions || !permissions.length) {
      return;
    }
    return this.permissions.some((permission) => permissions.includes(permission));
  }

  hasPermission(permission: DisputePermissionsEnum) {
    if (!permission) {
      return;
    }
    return this.permissions.includes(permission);
  }

  isAuthorizedUser(): boolean {
    return !!this.currentRoles && !!this.currentRoles.length;
  }

  verifyRole(role) {
    let isValidRole = false;
    _.each(this.currentUser.roles, (element) => {
      element = element.trim();
      if (
        `${UserDomain.CTS}/${role}` === element ||
        `${UserDomain.CTS_AD}/${role}` === element ||
        `${UserDomain.CFI}/${role}` === element ||
        `${UserDomain.CFI_AD}/${role}` === element ||
        `${UserDomain.CONWAY}/${role}` === element ||
        `${UserDomain.CONWAY_AD}/${role}` === element
      ) {
        isValidRole = true;
      }
    });
    return isValidRole;
  }

  isRequestor(): boolean {
    return this.verifyRole(this.disputesRolesPermissionsService.getRoleFromConfig(DisputeRolesEnum.REQUESTER));
  }

  /**
   * User is approver if can Approve some dispute type
   */
  isApprover(): boolean {
    return this.hasAnyPermission([
      DisputePermissionsEnum.APPROVE_DISPUTE_MRRA,
      DisputePermissionsEnum.APPROVE_DISPUTE_OCC,
      DisputePermissionsEnum.APPROVE_DISPUTE_WNI,
      DisputePermissionsEnum.APPROVE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_SVP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_PENDING_VP_APPROVAL_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_CE,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_LESS_THAN_1000,
      DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_MORE_THAN_EQUAL_1000,
    ]);
  }

  isDisputeAssignToMe(dispute: GetDisputeResp): boolean {
    if (!dispute || !dispute) {
      return;
    }
    return dispute.dispute.assignedToEmployeeId === this.getEmployeeId();
  }

  isDisputeAssigned(dispute: GetDisputeResp): boolean {
    if (!dispute || !dispute) {
      return;
    }
    return !!dispute.dispute.assignedToEmployeeId;
  }

  isDisputeCreatedByMe(dispute: GetDisputeResp): boolean {
    if (!dispute || !dispute) {
      return;
    }
    return dispute.dispute.filedByEmployeeId === this.getEmployeeId();
  }

  wasDisputeCreatedForMe(dispute: GetDisputeResp): boolean {
    if (!dispute || !dispute) {
      return;
    }
    return dispute.dispute.filedByEmployeeId === this.getEmployeeId();
  }

  /**
   * If in feature any role can't create certain dispute type
   */
  canCreateDispute(disputeType?: DisputeTypeCd): boolean {
    return this.hasPermission(DisputePermissionsEnum.CREATE_DISPUTE);
  }

  /**
   * If in feature any role can't create certain dispute type
   */
  canDuplicateDispute(disputeType?: DisputeTypeCd): boolean {
    return this.hasPermission(DisputePermissionsEnum.DUPLICATE_DISPUTE);
  }

  canViewDispute(dispute: GetDisputeResp): boolean {
    const canEditDispute = this.canEditDispute(dispute);
    if (canEditDispute) {
      return true;
    } else {
      return this.hasPermission(DisputePermissionsEnum.VIEW_ALL_DISPUTE);
    }
  }

  canSubmitDispute(dispute: GetDisputeResp): boolean {
    // DRP Category is not checked because can change in edition
    if (!dispute || (DisputeStatusHelperService.isDisputeDraft(dispute) && this.isDisputeAssignToMe(dispute))) {
      return this.hasPermission(DisputePermissionsEnum.SUBMIT_DISPUTE);
    } else if (
      DisputeStatusHelperService.isUnderReview(dispute) &&
      this.isDisputeAssignToMe(dispute) &&
      this.disputesDataService.editMode !== DisputeEditModesEnum.EDIT_DISPUTE
    ) {
      return this.hasPermission(DisputePermissionsEnum.SUBMIT_UNDER_REVIEW_DISPUTE);
    }
    return false;
  }

  canSubmitForReviewDispute(dispute: GetDisputeResp): boolean {
    // DRP Category is not checked because can change in edition
    if (!dispute || DisputeStatusHelperService.isDisputeDraft(dispute)) {
      return this.hasPermission(DisputePermissionsEnum.SUBMIT_DISPUTE_FOR_REVIEW);
    } else if (
      DisputeStatusHelperService.isUnderReview(dispute) &&
      this.isDisputeAssignToMe(dispute) &&
      this.disputesDataService.editMode !== DisputeEditModesEnum.EDIT_DISPUTE &&
      dispute.dispute.totalDisputeAmount >= 1000
    ) {
      return this.hasPermission(DisputePermissionsEnum.SUBMIT_UNDER_REVIEW_DISPUTE_FOR_REVIEW);
    }
    return false;
  }

  canSubmitForApproveDispute(dispute: GetDisputeResp): boolean {
    // DRP Category is not checked because can change in edition
    if (
      DisputeStatusHelperService.isUnderReview(dispute) &&
      this.isDisputeAssignToMe(dispute) &&
      this.disputesDataService.editMode !== DisputeEditModesEnum.EDIT_DISPUTE &&
      dispute.dispute.totalDisputeAmount >= 1000
    ) {
      return this.hasPermission(DisputePermissionsEnum.SUBMIT_DISPUTE_FOR_APPROVE);
    }
    return false;
  }

  canSaveAsDraft(dispute: GetDisputeResp) {
    if (!dispute || DisputeStatusHelperService.isDisputeDraft(dispute)) {
      return this.hasPermission(DisputePermissionsEnum.SAVE_AS_DRAFT);
    }
    return false;
  }

  canUpdateDispute(dispute: GetDisputeResp): boolean {
    if (
      !dispute ||
      !dispute.dispute ||
      !dispute.dispute.statusCd ||
      DisputeStatusHelperService.isDisputeDraft(dispute) ||
      DisputeStatusHelperService.isDisputeClose(dispute) ||
      !this.isDisputeAssignToMe(dispute) ||
      this.disputesDataService.editMode !== DisputeEditModesEnum.EDIT_DISPUTE
    ) {
      return;
    }

    switch (dispute.dispute.typeCd) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        return this.canUpdateOCCDispute(dispute);
      case DisputeTypeCd.INSPECTION_APPEALS:
        return this.canUpdateWNIDispute(dispute);
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        return this.canUpdateDRPDispute(dispute);
      default:
        return false;
    }
  }

  private canUpdateDRPDispute(dispute: GetDisputeResp): boolean {
    const permission =
      dispute.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION
        ? DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS_CE
        : DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS;
    // if (permission === DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS_CE) {
    //   return dispute.dispute.totalDisputeAmount < 1000 ? this.hasPermission(permission) : false;
    // }
    return this.hasPermission(permission);
  }

  private canUpdateOCCDispute(dispute: GetDisputeResp): boolean {
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS:
        return this.hasPermission(DisputePermissionsEnum.UPDATE_OVERCHARGE_CLAIMS_CORRECTIONS_UNDER_PROGRESS);
      default:
        return this.hasPermission(DisputePermissionsEnum.UPDATE_OVERCHARGE_CLAIMS);
    }
  }

  private canUpdateWNIDispute(dispute: GetDisputeResp): boolean {
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS:
        return this.hasPermission(DisputePermissionsEnum.UPDATE_INSPECTION_APPEALS_CORRECTIONS_UNDER_PROGRESS);
      default:
        return this.hasPermission(DisputePermissionsEnum.UPDATE_INSPECTION_APPEALS);
    }
  }

  canEditDispute(dispute: GetDisputeResp): boolean {
    if (
      !dispute ||
      !dispute.dispute ||
      !dispute.dispute.statusCd ||
      DisputeStatusHelperService.isDisputeClose(dispute) ||
      !this.isDisputeAssignToMe(dispute) ||
      this.disputesDataService.editMode === DisputeEditModesEnum.EDIT_DISPUTE ||
      dispute.dispute.statusCd === DisputeStatusCd.APPROVED
    ) {
      return;
    }

    switch (dispute.dispute.typeCd) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        return this.canUpdateOCCDispute(dispute);
      case DisputeTypeCd.INSPECTION_APPEALS:
        return this.canUpdateWNIDispute(dispute);
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        return this.canEditDRPDispute(dispute);
      default:
        return false;
    }
    // switch (dispute.dispute.statusCd) {
    //   case DisputeStatusCd.DRAFT:
    //     return this.hasPermission(DisputePermissionsEnum.EDIT_DRAFT_DISPUTE);
    //   case DisputeStatusCd.SUBMITTED:
    //     return this.hasPermission(DisputePermissionsEnum.EDIT_SUBMITTED_DISPUTE);
    //   case DisputeStatusCd.UNDER_REVIEW:
    //     return this.hasPermission(DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE);
    //   default:
    //     return false;
    // }
  }

  canEditDRPDispute(dispute: GetDisputeResp): boolean {
    const permission =
      dispute.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION
        ? DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS_CE
        : DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS;
    // if (permission === DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS_CE) {
    //   return dispute.dispute.totalDisputeAmount < 1000 ? this.hasPermission(permission) : false;
    // }
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.UNDER_REVIEW:
      case DisputeStatusCd.COLLECTIONS_ESCALATION_UNDER_REVIEW:
        const ediPermission =
          dispute.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION
            ? DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS_CE
            : DisputePermissionsEnum.UPDATE_DISPUTE_RESOLUTION_PROCESS;
        return this.hasPermission(ediPermission);
      case DisputeStatusCd.PENDING_DIRECTOR_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.UPDATE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_VP_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.UPDATE_PENDING_VP_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_SVP_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.UPDATE_PENDING_SVP_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_FINANCE_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.UPDATE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP);
      default:
        return this.hasPermission(permission);
    }
  }

  private canEditUnderReviewDrpDispute(dispute): DisputePermissionsEnum {
    if (dispute.dispute.totalDisputeAmount === 0 && this.hasRole([DisputeRolesEnum.SERVICE_CENTER_MANAGER])) {
      return DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_EQUAL_TO_ZERO;
    }
    if (dispute.dispute.totalDisputeAmount <= 300 && this.hasRole([DisputeRolesEnum.NAE])) {
      return DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_UP_TO_300;
    }
    if (dispute.dispute.totalDisputeAmount <= 500 && this.hasRole([DisputeRolesEnum.CUSTOMER_CARE_SUPERVISOR])) {
      return DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_UP_TO_500;
    }
    if (dispute.dispute.totalDisputeAmount < 2000 && this.hasRole([DisputeRolesEnum.DISPUTE_DIRECTOR])) {
      return DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000;
    }
    if (dispute.dispute.totalDisputeAmount < 10000 && this.hasRole([DisputeRolesEnum.VP_MARKETING])) {
      return DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000;
    }
    if (dispute.dispute.totalDisputeAmount < 25000 && this.hasRole([DisputeRolesEnum.SVP_SALES])) {
      return DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_25000;
    }
    return DisputePermissionsEnum.EDIT_UNDER_REVIEW_DISPUTE_DRP_ANY_AMT;
  }

  canGrabDispute(dispute: GetDisputeResp): boolean {
    if (!dispute || !dispute.dispute) {
      return;
    }
    if (
      DisputeStatusHelperService.isDisputeClose(dispute) ||
      DisputeStatusHelperService.isAny(dispute, [
        DisputeStatusCd.DRAFT,
        DisputeStatusCd.UNDER_REVIEW,
        DisputeStatusCd.COLLECTIONS_ESCALATION_UNDER_REVIEW,
        DisputeStatusCd.APPROVED,
      ]) ||
      this.isDisputeAssigned(dispute)
    ) {
      return false;
    }

    switch (dispute.dispute.typeCd) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        return this.canGrabOCCDisputes(dispute);
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        return this.canGrabDRPDispute(dispute);
      case DisputeTypeCd.INSPECTION_APPEALS:
        return this.canGrabWNIDisputes(dispute);
      case DisputeTypeCd.MASS_RE_RATE:
        return this.canGrabMRRADisputes(dispute);
      default:
        return false;
    }
  }

  /**
   * Check if user is able to reassign (in general)
   */
  canReassignDisputes(): boolean {
    return this.hasAnyPermission([
      DisputePermissionsEnum.REASSIGN_DISPUTE,
      DisputePermissionsEnum.REASSIGN_DISPUTE_OCC,
      DisputePermissionsEnum.REASSIGN_DISPUTE_OCC_CORRECTIONS_UNDER_PROGRESS,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP_CORRECTIONS_UNDER_PROGRESS,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP_CE,
      DisputePermissionsEnum.REASSIGN_DISPUTE_DRP_CE_CORRECTIONS_UNDER_PROGRESS,
      DisputePermissionsEnum.REASSIGN_DISPUTE_WNI,
      DisputePermissionsEnum.REASSIGN_DISPUTE_WNI_CORRECTIONS_UNDER_PROGRESS,
      DisputePermissionsEnum.REASSIGN_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
    ]);
  }

  /**
   * Check if user can reassign a specific dispute
   */
  canReassignDispute(dispute: GetDisputeResp): boolean {
    if (!dispute || !dispute.dispute) {
      return;
    }
    if (
      DisputeStatusHelperService.isDisputeClose(dispute) ||
      DisputeStatusHelperService.isAny(dispute, [
        DisputeStatusCd.DRAFT,
        DisputeStatusCd.SUBMITTED,
        DisputeStatusCd.SUBMITTED_FOR_REVIEW,
      ])
    ) {
      return false;
    }

    switch (dispute.dispute.typeCd) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        return this.canReassignOCCDisputes(dispute);
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        return this.canReassignDRPDisputes(dispute);
      case DisputeTypeCd.INSPECTION_APPEALS:
        return this.canReassignWNIDisputes(dispute);
      default:
        return false;
    }
  }

  /**
   * Handle OCC Dispute for all status
   */
  private canReassignOCCDisputes(dispute: GetDisputeResp) {
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS:
        return this.hasPermission(DisputePermissionsEnum.REASSIGN_DISPUTE_OCC_CORRECTIONS_UNDER_PROGRESS);
      case DisputeStatusCd.UNDER_REVIEW:
        return this.hasPermission(DisputePermissionsEnum.REASSIGN_DISPUTE_OCC);
      default:
        return false;
    }
  }

  /**
   * Handle WNI Dispute for all status
   */
  private canReassignWNIDisputes(dispute: GetDisputeResp) {
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS:
        return this.hasPermission(DisputePermissionsEnum.REASSIGN_DISPUTE_WNI_CORRECTIONS_UNDER_PROGRESS);
      case DisputeStatusCd.UNDER_REVIEW:
        return this.hasPermission(DisputePermissionsEnum.REASSIGN_DISPUTE_WNI);
      default:
        return false;
    }
  }

  /**
   * Handle DRP Dispute for all status
   */
  private canReassignDRPDisputes(dispute: GetDisputeResp) {
    const permission =
      dispute.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION
        ? DisputePermissionsEnum.REASSIGN_DISPUTE_DRP_CE
        : DisputePermissionsEnum.REASSIGN_DISPUTE_DRP;
    if (
      DisputeStatusHelperService.isAny(dispute, [
        DisputeStatusCd.UNDER_REVIEW,
        DisputeStatusCd.COLLECTIONS_ESCALATION_UNDER_REVIEW,
        DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS,
        DisputeStatusCd.PENDING_DIRECTOR_APPROVAL,
        DisputeStatusCd.PENDING_DIRECTOR_APPROVAL,
        DisputeStatusCd.PENDING_VP_APPROVAL,
        DisputeStatusCd.PENDING_SVP_APPROVAL,
        DisputeStatusCd.PENDING_FINANCE_APPROVAL,
      ])
    ) {
      return this.hasPermission(permission);
    }
    return false;
  }

  /**
   * Check if user is able to un-assign (in general)
   */
  canUnassignDisputes(): boolean {
    return this.hasAnyPermission([
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP_CE,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_OCC,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_OCC_CORRECTIONS_UNDER_PROGRESS,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_WNI,
      DisputePermissionsEnum.UN_ASSIGN_DISPUTE_WNI_CORRECTIONS_UNDER_PROGRESS,
      DisputePermissionsEnum.UN_ASSIGN_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP,
    ]);
  }

  /**
   * Check if user can un-assign a specific dispute
   */
  canUnassignDispute(dispute: GetDisputeResp): boolean {
    if (!dispute || !dispute.dispute) {
      return;
    }
    if (
      DisputeStatusHelperService.isDisputeClose(dispute) ||
      DisputeStatusHelperService.isAny(dispute, [
        DisputeStatusCd.DRAFT,
        DisputeStatusCd.SUBMITTED_FOR_REVIEW,
        DisputeStatusCd.SUBMITTED,
      ])
    ) {
      return false;
    }

    switch (dispute.dispute.typeCd) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        return this.canUnassignOCCDisputes(dispute);
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        return this.canUnassignDRPDisputes(dispute);
      case DisputeTypeCd.INSPECTION_APPEALS:
        return this.canUnassignWNIDisputes(dispute);
      default:
        return false;
    }
  }

  /**
   * Handle OCC Dispute for all status
   */
  private canUnassignOCCDisputes(dispute: GetDisputeResp) {
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS:
        return this.hasPermission(DisputePermissionsEnum.UN_ASSIGN_DISPUTE_OCC_CORRECTIONS_UNDER_PROGRESS);
      case DisputeStatusCd.UNDER_REVIEW:
        return this.hasPermission(DisputePermissionsEnum.UN_ASSIGN_DISPUTE_OCC);
      default:
        return false;
    }
  }

  /**
   * Handle WNI Dispute for all status
   */
  private canUnassignWNIDisputes(dispute: GetDisputeResp) {
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS:
        return this.hasPermission(DisputePermissionsEnum.UN_ASSIGN_DISPUTE_WNI_CORRECTIONS_UNDER_PROGRESS);
      case DisputeStatusCd.UNDER_REVIEW:
        return this.hasPermission(DisputePermissionsEnum.UN_ASSIGN_DISPUTE_WNI);
      default:
        return false;
    }
  }

  /**
   * Handle DRP Dispute for all status
   */
  private canUnassignDRPDisputes(dispute: GetDisputeResp) {
    const permission =
      dispute.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION
        ? DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP_CE
        : DisputePermissionsEnum.UN_ASSIGN_DISPUTE_DRP;
    if (
      DisputeStatusHelperService.isAny(dispute, [
        DisputeStatusCd.UNDER_REVIEW,
        DisputeStatusCd.COLLECTIONS_ESCALATION_UNDER_REVIEW,
        DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS,
        DisputeStatusCd.PENDING_DIRECTOR_APPROVAL,
        DisputeStatusCd.PENDING_VP_APPROVAL,
        DisputeStatusCd.PENDING_SVP_APPROVAL,
        DisputeStatusCd.PENDING_FINANCE_APPROVAL,
      ])
    ) {
      return this.hasPermission(permission);
    }
    return false;
  }

  /**
   * Handle OCC Dispute for all status
   */
  private canGrabOCCDisputes(dispute: GetDisputeResp) {
    let canGrab = false;
    if (dispute && dispute.dispute && DisputeStatusHelperService.isDisputeSubmitted(dispute)) {
      canGrab = this.hasPermission(DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_OCC);
    }
    if (!canGrab && this.isDisputeCreatedByMe(dispute)) {
      return this.hasPermission(DisputePermissionsEnum.GRAB_MY_SUBMITTED_DISPUTE_OCC);
    }
    return canGrab;
  }

  /**
   * Handle WNI Dispute for all status
   */
  private canGrabWNIDisputes(dispute: GetDisputeResp) {
    let canGrab = false;
    if (dispute && dispute.dispute && DisputeStatusHelperService.isDisputeSubmitted(dispute)) {
      canGrab = this.hasPermission(DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_WNI);
    }
    if (!canGrab && this.isDisputeCreatedByMe(dispute)) {
      return this.hasPermission(DisputePermissionsEnum.GRAB_MY_SUBMITTED_DISPUTE_WNI);
    }
    return canGrab;
  }

  /**
   * Handle grab permission for MRRA Dispute for all status
   */
  private canGrabMRRADisputes(dispute: GetDisputeResp) {
    let canGrab = false;
    if (dispute && dispute.dispute && DisputeStatusHelperService.isDisputeSubmitted(dispute)) {
      canGrab = this.hasPermission(DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_MRRA);
    }
    if (!canGrab && this.isDisputeCreatedByMe(dispute)) {
      return this.hasPermission(DisputePermissionsEnum.GRAB_MY_SUBMITTED_DISPUTE_MRRA);
    }
    return canGrab;
  }

  /**
   * Handle grab permission for DRP Dispute for all status
   */
  private canGrabDRPDispute(dispute: GetDisputeResp): boolean {
    if (!dispute || !dispute.dispute) {
      return;
    }
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.SUBMITTED:
        const submittionPermission =
          dispute.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION
            ? DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_CE
            : DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP;
        return this.hasPermission(submittionPermission);
      // }
      // return canGrabSubmitted;
      case DisputeStatusCd.SUBMITTED_FOR_REVIEW:
        const submittedUnderReviewPermission =
          dispute.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION
            ? DisputePermissionsEnum.GRAB_SUBMITTED_FOR_REVIEW_DISPUTE_CE
            : DisputePermissionsEnum.GRAB_SUBMITTED_FOR_REVIEW_DISPUTE_DRP;
        return this.hasPermission(submittedUnderReviewPermission);
      case DisputeStatusCd.PENDING_DIRECTOR_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.GRAB_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_VP_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.GRAB_PENDING_VP_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_VPS_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.GRAB_PENDING_VPS_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_SVP_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.GRAB_PENDING_SVP_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_FINANCE_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.GRAB_PENDING_FINANCE_APPROVAL_DISPUTE_DRP);
      default:
        return false;
    }
  }

  private canGrabSubmittedDrpDispute(dispute): DisputePermissionsEnum {
    if (dispute.dispute.totalDisputeAmount === 0 && this.hasRole([DisputeRolesEnum.SERVICE_CENTER_MANAGER])) {
      return DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_EQUAL_TO_ZERO;
    }
    if (dispute.dispute.totalDisputeAmount <= 300 && this.hasRole([DisputeRolesEnum.NAE])) {
      return DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_UP_TO_300;
    }
    if (dispute.dispute.totalDisputeAmount <= 500 && this.hasRole([DisputeRolesEnum.CUSTOMER_CARE_SUPERVISOR])) {
      return DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_UP_TO_500;
    }
    if (dispute.dispute.totalDisputeAmount < 2000 && this.hasRole([DisputeRolesEnum.DISPUTE_DIRECTOR])) {
      return DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_2000;
    }
    if (dispute.dispute.totalDisputeAmount < 10000 && this.hasRole([DisputeRolesEnum.VP_MARKETING])) {
      return DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_10000;
    }
    if (dispute.dispute.totalDisputeAmount < 25000 && this.hasRole([DisputeRolesEnum.SVP_SALES])) {
      return DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_LESS_THAN_25000;
    }
    return DisputePermissionsEnum.GRAB_SUBMITTED_DISPUTE_DRP_ANY_AMT;
  }

  /**
   * Return if user can approve a dispute, except OCC type
   */
  canApproveDispute(dispute: GetDisputeResp): boolean {
    if (
      !dispute ||
      DisputeStatusHelperService.isDisputeDraft(dispute) ||
      DisputeStatusHelperService.isDisputeClose(dispute) ||
      DisputeStatusHelperService.isCorrectionsUnderProgress(dispute) ||
      !this.isDisputeAssignToMe(dispute)
    ) {
      return false;
    }
    switch (dispute.dispute.typeCd) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        return this.hasPermission(DisputePermissionsEnum.APPROVE_DISPUTE_OCC);
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        return this.canApproveDRPDispute(dispute);
      case DisputeTypeCd.INSPECTION_APPEALS:
        return this.hasPermission(DisputePermissionsEnum.APPROVE_DISPUTE_WNI);
      case DisputeTypeCd.MASS_RE_RATE:
        return this.hasPermission(DisputePermissionsEnum.APPROVE_DISPUTE_MRRA);
      default:
        return false;
    }
  }

  /**
   * Handle approve permission for DRP Dispute for all status
   */
  private canApproveDRPDispute(dispute: GetDisputeResp): boolean {
    if (!dispute || !dispute.dispute) {
      return;
    }
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.UNDER_REVIEW:
      case DisputeStatusCd.COLLECTIONS_ESCALATION_UNDER_REVIEW:
        const permission =
          dispute.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION
            ? dispute.dispute.totalDisputeAmount < 1000
              ? DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_LESS_THAN_1000
              : DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_MORE_THAN_EQUAL_1000
            : DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP;
        return this.hasPermission(permission);
      case DisputeStatusCd.PENDING_DIRECTOR_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.APPROVE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_VP_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.APPROVE_PENDING_VP_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_VPS_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.APPROVE_PENDING_VPS_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_SVP_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.APPROVE_PENDING_SVP_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_FINANCE_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.APPROVE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP);
      default:
        return false;
    }
  }

  private canApproveUnderReviewDrpDispute(dispute): DisputePermissionsEnum {
    if (dispute.dispute.totalDisputeAmount === 0 && this.hasRole([DisputeRolesEnum.SERVICE_CENTER_MANAGER])) {
      return DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_EQUAL_TO_ZERO;
    }
    if (dispute.dispute.totalDisputeAmount <= 300 && this.hasRole([DisputeRolesEnum.NAE])) {
      return DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_UP_TO_300;
    }
    if (dispute.dispute.totalDisputeAmount <= 500 && this.hasRole([DisputeRolesEnum.CUSTOMER_CARE_SUPERVISOR])) {
      return DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_UP_TO_500;
    }
    if (dispute.dispute.totalDisputeAmount < 2000 && this.hasRole([DisputeRolesEnum.DISPUTE_DIRECTOR])) {
      return DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000;
    }
    if (dispute.dispute.totalDisputeAmount < 10000 && this.hasRole([DisputeRolesEnum.VP_MARKETING])) {
      return DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000;
    }
    if (dispute.dispute.totalDisputeAmount < 25000 && this.hasRole([DisputeRolesEnum.SVP_SALES])) {
      return DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_25000;
    }
    return DisputePermissionsEnum.APPROVE_UNDER_REVIEW_DISPUTE_DRP_ANY_AMT;
  }

  canCancelDispute(dispute: GetDisputeResp): boolean {
    if (
      !dispute ||
      DisputeStatusHelperService.isDisputeDraft(dispute) ||
      DisputeStatusHelperService.isDisputeClose(dispute) ||
      DisputeStatusHelperService.isCorrectionsUnderProgress(dispute) ||
      !this.isDisputeAssignToMe(dispute)
    ) {
      return false;
    }
    switch (dispute.dispute.typeCd) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        return this.hasPermission(DisputePermissionsEnum.CANCEL_DISPUTE_OCC);
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        return this.canCancelDRPDispute(dispute);
      case DisputeTypeCd.INSPECTION_APPEALS:
        return this.hasPermission(DisputePermissionsEnum.CANCEL_DISPUTE_WNI);
      case DisputeTypeCd.MASS_RE_RATE:
        return this.hasPermission(DisputePermissionsEnum.CANCEL_DISPUTE_MRRA);
      default:
        return false;
    }
  }

  /**
   * Handle cancel permission for DRP Dispute for all status
   */
  private canCancelDRPDispute(dispute: GetDisputeResp): boolean {
    if (!dispute || !dispute.dispute) {
      return;
    }
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.UNDER_REVIEW:
      case DisputeStatusCd.COLLECTIONS_ESCALATION_UNDER_REVIEW:
        const permission =
          dispute.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION
            ? dispute.dispute.totalDisputeAmount < 1000
              ? DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_LESS_THAN_1000
              : DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_MORE_THAN_EQUAL_1000
            : DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP;
        return this.hasPermission(permission);
      case DisputeStatusCd.PENDING_DIRECTOR_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.CANCEL_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_VP_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.CANCEL_PENDING_VP_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_VPS_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.CANCEL_PENDING_VPS_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_SVP_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.CANCEL_PENDING_SVP_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_FINANCE_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.CANCEL_PENDING_FINANCE_APPROVAL_DISPUTE_DRP);
      default:
        return false;
    }
  }

  private canCancelUnderReviewDrpDispute(dispute): DisputePermissionsEnum {
    if (dispute.dispute.totalDisputeAmount === 0 && this.hasRole([DisputeRolesEnum.SERVICE_CENTER_MANAGER])) {
      return DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_EQUAL_TO_ZERO;
    }
    if (dispute.dispute.totalDisputeAmount <= 300 && this.hasRole([DisputeRolesEnum.NAE])) {
      return DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_UP_TO_300;
    }
    if (dispute.dispute.totalDisputeAmount <= 500 && this.hasRole([DisputeRolesEnum.CUSTOMER_CARE_SUPERVISOR])) {
      return DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_UP_TO_500;
    }
    if (dispute.dispute.totalDisputeAmount < 2000 && this.hasRole([DisputeRolesEnum.DISPUTE_DIRECTOR])) {
      return DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000;
    }
    if (dispute.dispute.totalDisputeAmount < 10000 && this.hasRole([DisputeRolesEnum.VP_MARKETING])) {
      return DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000;
    }
    if (dispute.dispute.totalDisputeAmount < 25000 && this.hasRole([DisputeRolesEnum.SVP_SALES])) {
      return DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_25000;
    }
    return DisputePermissionsEnum.CANCEL_UNDER_REVIEW_DISPUTE_DRP_ANY_AMT;
  }

  canDeclineDispute(dispute: GetDisputeResp): boolean {
    if (
      !dispute ||
      DisputeStatusHelperService.isDisputeDraft(dispute) ||
      DisputeStatusHelperService.isDisputeClose(dispute) ||
      !this.isDisputeAssignToMe(dispute) ||
      this.disputesDataService.editMode === DisputeEditModesEnum.EDIT_DISPUTE
    ) {
      return false;
    }
    switch (dispute.dispute.typeCd) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        return this.canDeclineOCCDispute(dispute);
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        return this.canDeclineDRPDispute(dispute);
      case DisputeTypeCd.INSPECTION_APPEALS:
        return this.canDeclineWNIDispute(dispute);
      case DisputeTypeCd.MASS_RE_RATE:
        return this.hasPermission(DisputePermissionsEnum.DECLINE_DISPUTE_MRRA);
      default:
        return false;
    }
  }

  canDeclineOCCDispute(dispute) {
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS:
        return this.hasPermission(DisputePermissionsEnum.DECLINE_CORRECTIONS_UNDER_PROGRESS_DISPUTE_OCC);
      default:
        return this.hasPermission(DisputePermissionsEnum.DECLINE_DISPUTE_OCC);
    }
  }

  canDeclineWNIDispute(dispute) {
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS:
        return this.hasPermission(DisputePermissionsEnum.DECLINE_CORRECTIONS_UNDER_PROGRESS_DISPUTE_WNI);
      default:
        return this.hasPermission(DisputePermissionsEnum.DECLINE_DISPUTE_WNI);
    }
  }

  /**
   * Handle decline permission for DRP Dispute for all status
   */
  private canDeclineDRPDispute(dispute: GetDisputeResp): boolean {
    if (!dispute || !dispute.dispute) {
      return;
    }
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.UNDER_REVIEW:
      case DisputeStatusCd.COLLECTIONS_ESCALATION_UNDER_REVIEW:
        const permission =
          dispute.dispute.drpCategoryCd === DisputeDrpCategoryCd.DRP_COLLECTIONS_ESCALATION
            ? dispute.dispute.totalDisputeAmount < 1000
              ? DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_LESS_THAN_1000
              : DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_COLL_ESC_AMT_MORE_THAN_EQUAL_1000
            : DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP;
        return this.hasPermission(permission);
      case DisputeStatusCd.PENDING_DIRECTOR_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.DECLINE_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_VP_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.DECLINE_PENDING_VP_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_VPS_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.DECLINE_PENDING_VPS_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_SVP_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.DECLINE_PENDING_SVP_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_FINANCE_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.DECLINE_PENDING_FINANCE_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS:
        return this.hasPermission(DisputePermissionsEnum.DECLINE_CORRECTIONS_UNDER_PROGRESS_DISPUTE_DRP);
      default:
        return false;
    }
  }

  private canDeclineUnderReviewDrpDispute(dispute): DisputePermissionsEnum {
    if (dispute.dispute.totalDisputeAmount === 0 && this.hasRole([DisputeRolesEnum.SERVICE_CENTER_MANAGER])) {
      return DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_EQUAL_TO_ZERO;
    }
    if (dispute.dispute.totalDisputeAmount <= 300 && this.hasRole([DisputeRolesEnum.NAE])) {
      return DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_UP_TO_300;
    }
    if (dispute.dispute.totalDisputeAmount <= 500 && this.hasRole([DisputeRolesEnum.CUSTOMER_CARE_SUPERVISOR])) {
      return DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_UP_TO_500;
    }
    if (dispute.dispute.totalDisputeAmount < 2000 && this.hasRole([DisputeRolesEnum.DISPUTE_DIRECTOR])) {
      return DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_2000;
    }
    if (dispute.dispute.totalDisputeAmount < 10000 && this.hasRole([DisputeRolesEnum.VP_MARKETING])) {
      return DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_10000;
    }
    if (dispute.dispute.totalDisputeAmount < 25000 && this.hasRole([DisputeRolesEnum.SVP_SALES])) {
      return DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_LESS_THAN_25000;
    }
    return DisputePermissionsEnum.DECLINE_UNDER_REVIEW_DISPUTE_DRP_ANY_AMT;
  }

  canAmendDispute(dispute: GetDisputeResp): boolean {
    if (
      !dispute ||
      DisputeStatusHelperService.isDisputeClose(dispute) ||
      DisputeStatusHelperService.isCorrectionsUnderProgress(dispute) ||
      !this.isDisputeAssignToMe(dispute)
    ) {
      return false;
    }
    switch (dispute.dispute.typeCd) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        return this.hasPermission(DisputePermissionsEnum.AMEND_DISPUTE_OCC);
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        return this.canAmendDRPDispute(dispute);
      case DisputeTypeCd.INSPECTION_APPEALS:
        return this.hasPermission(DisputePermissionsEnum.AMEND_DISPUTE_WNI);
      case DisputeTypeCd.MASS_RE_RATE:
        return this.hasPermission(DisputePermissionsEnum.AMEND_DISPUTE_MRRA);
      default:
        return false;
    }
  }

  canStartCorrections(dispute: GetDisputeResp): boolean {
    if (
      dispute &&
      (dispute.dispute.statusCd === DisputeStatusCd.AMENDED || dispute.dispute.statusCd === DisputeStatusCd.APPROVED)
    ) {
      return (
        this.hasPermission(DisputePermissionsEnum.CAN_START_CORRECTIONS) &&
        this.hasPermission(DisputePermissionsEnum.VIEW_ALL_DISPUTE)
      );
    }
    return false;
  }

  canCloseDispue(dispute: GetDisputeResp): boolean {
    if (
      dispute &&
      dispute.dispute.statusCd === DisputeStatusCd.CORRECTIONS_UNDER_PROGRESS &&
      this.isDisputeAssignToMe(dispute) &&
      this.disputesDataService.editMode !== DisputeEditModesEnum.EDIT_DISPUTE
    ) {
      return this.hasPermission(DisputePermissionsEnum.CAN_CORRECTIONS_COMPLETE);
    }
    return false;
  }

  /**
   * Handle amend permission for DRP Dispute for all status
   */
  private canAmendDRPDispute(dispute: GetDisputeResp): boolean {
    if (!dispute || !dispute.dispute) {
      return;
    }
    switch (dispute.dispute.statusCd) {
      case DisputeStatusCd.UNDER_REVIEW:
      case DisputeStatusCd.COLLECTIONS_ESCALATION_UNDER_REVIEW:
        return this.hasPermission(DisputePermissionsEnum.AMEND_UNDER_REVIEW_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_DIRECTOR_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.AMEND_PENDING_DIRECTOR_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_VP_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.AMEND_PENDING_VP_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_VPS_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.AMEND_PENDING_VPS_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_SVP_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.AMEND_PENDING_SVP_APPROVAL_DISPUTE_DRP);
      case DisputeStatusCd.PENDING_FINANCE_APPROVAL:
        return this.hasPermission(DisputePermissionsEnum.AMEND_PENDING_FINANCE_APPROVAL_DISPUTE_DRP);
      default:
        return false;
    }
  }

  canDeleteDispute(dispute: GetDisputeResp): boolean {
    if (!dispute || !DisputeStatusHelperService.isDisputeDraft(dispute) || !this.isDisputeAssignToMe(dispute)) {
      return false;
    }
    return this.hasPermission(DisputePermissionsEnum.DELETE_DRAFT_DISPUTE);
  }

  // #region Common sections

  canAddRemoveEditRequestExplanation(dispute: GetDisputeResp): boolean {
    if (!dispute || DisputeStatusHelperService.isDisputeDraft(dispute)) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.ADD_REMOVE_EDIT_REQUEST_EXPLANATION);
  }

  canEditRequestReason(dispute: GetDisputeResp): boolean {
    if (!dispute || DisputeStatusHelperService.isDisputeDraft(dispute)) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.EDIT_REQUEST_REASON);
  }

  canAddRemovePros(dispute: GetDisputeResp): boolean {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.ADD_REMOVE_PROS);
  }

  canAddRemoveWatchers(dispute: GetDisputeResp): boolean {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.ADD_REMOVE_WATCHERS);
  }

  canAddRemoveDocuments(dispute: GetDisputeResp): boolean {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.ADD_REMOVE_DOCUMENTS);
  }

  canSendEmail(dispute: GetDisputeResp): boolean {
    // If there is not dispute, is a creation
    if (!dispute) {
      return false;
    }
    return this.hasPermission(DisputePermissionsEnum.SEND_EMAIL);
  }

  canAddNotes(dispute: GetDisputeResp): boolean {
    // If there is not dispute, is a creation
    if (!dispute) {
      return false;
    }
    return this.hasPermission(DisputePermissionsEnum.ADD_NOTES);
  }

  // #endregion

  // #region OCC Sections

  canAddRemoveEditClaimant(dispute: GetDisputeResp) {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.ADD_REMOVE_EDIT_CLAIMANT);
  }

  canAddRemoveEditClaimantContact(dispute: GetDisputeResp) {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.ADD_REMOVE_EDIT_CLAIMANT_CONTACT);
  }

  canAddRemoveEditOnBehalfOf(dispute: GetDisputeResp) {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.ADD_REMOVE_EDIT_ON_BEHALF_OF);
  }

  canAddRemoveEditOnBehalfOfContact(dispute: GetDisputeResp) {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.ADD_REMOVE_EDIT_ON_BEHALF_OF);
  }

  // #endregion

  // #region DRP Section

  canEditAeNae(dispute: GetDisputeResp) {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.EDIT_AE_NAE);
  }

  canEditAuthorizedApprover(dispute: GetDisputeResp) {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.EDIT_AUTHORIZED_APPROVER);
  }

  canAddRemoveEditDebtor(dispute: GetDisputeResp) {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR);
  }

  canAddRemoveEditDebtorContact(dispute: GetDisputeResp) {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.ADD_REMOVE_EDIT_DEBTOR_CONTACT);
  }

  canAddRemoveEditNewDebtor(dispute: GetDisputeResp) {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR);
  }

  canAddRemoveEditNewDebtorContact(dispute: GetDisputeResp) {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.ADD_REMOVE_EDIT_NEW_DEBTOR_CONTACT);
  }

  // #endregion

  // #region WNI Section
  canEditInspector(dispute: GetDisputeResp) {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.EDIT_INSPECTOR);
  }

  canEditShipperWebsite(dispute: GetDisputeResp) {
    // If there is not dispute, is a creation
    if (!dispute) {
      return true;
    }
    return this.hasPermission(DisputePermissionsEnum.EDIT_SHIPPER_WEBSITE);
  }
  // #endregion
}
