import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { XpoBrowserGuard } from '@xpo-ltl/ngx-ltl-core/browser-guard';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';

import { RouterParams, RouterUriComponents } from './enums';
import { RoleCheckGuard } from './guards/role-check-guard';
import { DisputeResolver } from './resolvers/dispute.resolver';
export const RouterDefinitions: Routes = [
  {
    path: RouterUriComponents.DISPUTES,
    loadChildren: () => import('./components/disputes/disputes.module').then((m) => m.DisputesModule),
    canActivate: [XpoAuthenticationGuard, RoleCheckGuard],
  },
  {
    path: RouterUriComponents.VIEW_DISPUTE_MOBILE,
    loadChildren: () => import('./components/disputes/mobile/disputes-mobile.module').then((m) => m.DisputesMobileModule),
    canActivate: [XpoAuthenticationGuard, RoleCheckGuard],
  },
  {
    path: RouterUriComponents.DASHBOARD,
    loadChildren: () => import('./components/dashboards/dashboards.module').then((m) => m.DashboardsModule),
    canActivate: [XpoAuthenticationGuard, RoleCheckGuard],
  },
  {
    path: `${RouterUriComponents.SHIPMENT_DETAIL}/:${RouterParams.SHIPMENT_ID}`,
    loadChildren: () => import('./components/shipments/shipments.module').then((m) => m.ShipmentsModule),
    canActivate: [XpoAuthenticationGuard, RoleCheckGuard],
  },
  {
    path: '',
    redirectTo: `/${RouterUriComponents.DASHBOARD}`,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: RouterUriComponents.DASHBOARD,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(RouterDefinitions, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
  providers: [RoleCheckGuard, DisputeResolver],
  declarations: [],
})
export class AppRoutingModule {}
