import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { XpoDialogsModule } from '@xpo-ltl/angular-utils';
import { XpoDialogsModule } from '../app/utils/angular-utils/dialogs/xpo-dialogs.module';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoPopoverModule } from '@xpo-ltl/ngx-ltl-core/popover';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoTabsModule } from '@xpo-ltl/ngx-ltl-core/tabs';
import { XpoBoardModule } from '@xpo-ltl/ngx-board/core';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-board/ag-grid';

import { MaterialModule } from './material.module';
import { EmployeePipe } from './pipes/employee.pipe';
import { HumanizePipe } from './pipes/humanize.pipe';
import { ProDashPipe } from './pipes/pro-dash.pipe';
import { XpoConditionalZipValidatorDirective } from './validators';

@NgModule({
  imports: [CommonModule],
  declarations: [HumanizePipe, EmployeePipe, XpoConditionalZipValidatorDirective, ProDashPipe],
  exports: [
    CommonModule,
    EmployeePipe,
    ProDashPipe,
    FormsModule,
    HumanizePipe,
    MaterialModule,
    ReactiveFormsModule,
    XpoAgGridBoardModule,
    XpoBoardModule,
    XpoButtonModule,
    XpoCardModule,
    XpoConditionalZipValidatorDirective,
    XpoDialogModule,
    XpoDialogsModule,
    XpoPopoverModule,
    XpoSnackBarModule,
    XpoTabsModule,
  ],
  providers: [],
})
export class SharedModule {}
