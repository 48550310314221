import { Injectable } from '@angular/core';
import { ApiRequest } from '@xpo-ltl/data-api';
import { InfrastructureApiService, SendEmailRqst } from '@xpo-ltl/sdk-infrastructure';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmailService {
  constructor(private infrastructureApi: InfrastructureApiService) {}

  /**
   * Submits a concealed call to infrastructure api to send email.
   * @param fromName - The name of the person who the email is from
   * @param fromEmail - The email address who the email is from
   * @param toEmail - Who the email is addressed to
   * @param subject - The subject of the email
   * @param messageBody - The message body
   */
  public sendEmail(fromName: string, fromEmail: string, toEmail: string, subject: string, messageBody: string): Observable<void> {
    return this.infrastructureApi.sendEmail(this.createEmail(fromName, fromEmail, toEmail, subject, messageBody), ApiRequest.concealedCall);
  }

  private createEmail(fromName: string, fromEmail: string, toEmail: string, subject: string, messageBody: string): SendEmailRqst {
    // TODO: If we ever allow user to enter in the from field, then we will need to do other validation.
    let from = fromEmail;
    if (fromName && fromName !== fromEmail) {
      // I am assuming fromEmail will always exist and valid.
      // Prepend fromName to email address so it will show up as user name instead of user email.
      from = fromName + '<' + fromEmail + '>';
    }
    return {
      emailMessage: {
        from: from,
        fromName: '',
        to: toEmail,
        subject: subject,
        messageBody: messageBody,
      },
    } as SendEmailRqst;
  }
}
