import { AfterContentInit, Component, ComponentFactoryResolver, Inject, Type, ViewChild, ViewEncapsulation } from '@angular/core';
import { DialogTemplateInjectorDirective } from '../dialog-template-injector.directive';
import { InjectedDialogComponent } from '../xpo-dialog-manager.service';
import { AlertDialogData } from '../../interfaces';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-alert-wrapper',
  templateUrl: './alert-wrapper.component.html',
  styleUrls: ['./alert-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertWrapperComponent implements AfterContentInit {
  @ViewChild(DialogTemplateInjectorDirective) messageContent: DialogTemplateInjectorDirective;

  constructor(private dialogRef: MatDialogRef<AlertWrapperComponent>, @Inject(MAT_DIALOG_DATA) public data: AlertDialogData, private componentFactoryResolver: ComponentFactoryResolver) {}

  ngAfterContentInit() {
    if (this.data.contentComponent) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.contentComponent);
      const componentRef = this.messageContent.viewContainerRef.createComponent(componentFactory);
      (componentRef.instance as InjectedDialogComponent).dataObservable = this.data.injectedData;
    }
  }

  handleCloseClick(): void {
    this.dialogRef.close();
  }
}
