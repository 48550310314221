import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import moment_ from 'moment';
const moment = moment_;

export function xpoDateValidator(alias?: string): ValidatorFn {
  const dateTester = new RegExp(/^\d{1,2}\/\d{1,2}\/\d{4}$/);
  return (control: AbstractControl): { [key: string]: any } => {
    const errorKey: string = alias || 'xpoDate';

    if (control.value) {
      if (moment.isMoment(control.value)) {
        const momentDate = moment(control.value);
        return momentDate.isValid() ? null : { [errorKey]: { value: control.value } };
      } else {
        return dateTester.test(control.value) ? null : { [errorKey]: { value: control.value } };
      }
    }
  };
}

export function xpoDateAsyncValidator(alias?: string): AsyncValidatorFn {
  return (control: AbstractControl): Observable<{ [key: string]: any }> => {
    return Observable.create(observer => {
      observer.next(xpoDateValidator(alias)(control));
    });
  };
}

@Directive({
  selector: '[xpoDate]',
  providers: [{ provide: NG_VALIDATORS, useExisting: XpoDateValidatorDirective, multi: true }],
})
export class XpoDateValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } {
    return xpoDateValidator()(control);
  }
}
