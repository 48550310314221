import { Party } from '@xpo-ltl/sdk-disputes';
import {
  XrtAttributeFilter,
  XrtCustomerSearchFilter,
  XrtCustomerSearchRqst,
  XrtSearchQueryHeader,
} from '@xpo-ltl/sdk-elasticsearch';

export class CustomerSearchHelper {
  static getRequestForXrtCustomerSearch(party: Party, pageNumber: number) {
    const request = new XrtCustomerSearchRqst();
    const queryHeader = new XrtSearchQueryHeader();
    queryHeader.page = pageNumber;
    let searchFilter = new XrtCustomerSearchFilter();
    searchFilter = this.addNameFilter(searchFilter, party);
    searchFilter = this.addAddressFilter(searchFilter, party);
    searchFilter = this.addCityFilter(searchFilter, party);
    searchFilter = this.addStateFilter(searchFilter, party);
    searchFilter = this.addPostalFilter(searchFilter, party);
    request.header = queryHeader;
    request.filter = searchFilter;
    return request;
  }

  static addNameFilter(filter: XrtCustomerSearchFilter, party: Party): XrtCustomerSearchFilter {
    if (party && party.name1) {
      const nameFilter = new XrtAttributeFilter();
      nameFilter.startsWith = party.name1;
      filter.name1 = nameFilter;
    }
    return filter;
  }

  static addAddressFilter(filter: XrtCustomerSearchFilter, party: Party): XrtCustomerSearchFilter {
    if (party && party.addr1) {
      const addressFilter = new XrtAttributeFilter();
      addressFilter.startsWith = party.addr1;
      filter.addressLine = addressFilter;
    }
    return filter;
  }

  static addCityFilter(filter: XrtCustomerSearchFilter, party: Party): XrtCustomerSearchFilter {
    if (party && party.cityName) {
      const cityFilter = new XrtAttributeFilter();
      cityFilter.equals = party.cityName;
      filter.cityName = cityFilter;
    }
    return filter;
  }

  static addStateFilter(filter: XrtCustomerSearchFilter, party: Party): XrtCustomerSearchFilter {
    if (party && party.stateCd) {
      const stateFilter = new XrtAttributeFilter();
      stateFilter.equals = party.stateCd;
      filter.stateCd = stateFilter;
    }
    return filter;
  }

  static addPostalFilter(filter: XrtCustomerSearchFilter, party: Party): XrtCustomerSearchFilter {
    if (party && party.postalCd) {
      const stateFilter = new XrtAttributeFilter();
      stateFilter.equals = party.postalCd;
      filter.postalCd = stateFilter;
    }
    return filter;
  }
}
