import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AlertWrapperComponent } from './dialogs/alert-wrapper/alert-wrapper.component';
import { DialogTemplateInjectorDirective } from './dialogs/dialog-template-injector.directive';

import { MaterialModule } from './material.module';
import { XpoDialogManagerService } from './services/xpo-dialog-manager.service';
@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [DialogTemplateInjectorDirective, AlertWrapperComponent],
  providers: [XpoDialogManagerService],
  entryComponents: [AlertWrapperComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppDialogsModule {}
