import { Injectable, Injector } from '@angular/core';

import { DisputeStatusCd } from '@xpo-ltl/sdk-common';
import { Dispute, InterfaceEmployee, UpdateDisputeRqst, UpsertDisputeRqst } from '@xpo-ltl/sdk-disputes';

import { DisputeDetailsFormNamesEnum } from '../../enums';
import { DisputesRegistrationService } from './disputes-registration.service';

@Injectable({
  providedIn: 'root',
})
export class WniDisputesRegistrationService extends DisputesRegistrationService {
  constructor(protected injector: Injector) {
    super(injector);
  }

  createUpsertDisputesReq(disputeStatus: DisputeStatusCd): UpsertDisputeRqst {
    const req: UpsertDisputeRqst = new UpsertDisputeRqst();
    req.dispute = this.createDispute();
    req.shipments = this.createShipmentForUpsert();
    req.employeeNotify = this.createEmployeeNotifyForUpsert();
    req.itemInstId = this.getItemInstId();
    req.disputeDocuments = this.createDisputeDocuments();
    return req;
  }

  protected createDispute(): Dispute {
    const dispute: Dispute = super.createDispute();
    const disputeDetailsForm = this.disputeFormGroup.get(DisputeDetailsFormNamesEnum.FORM_GROUP_NAME);
    const inspectorForm = this.disputeFormGroup
      .get(DisputeDetailsFormNamesEnum.FORM_GROUP_NAME)
      .get(DisputeDetailsFormNamesEnum.INSPECTOR_FORM_GROUP_NAME);

    dispute.shipperWebsiteUrl = disputeDetailsForm.get(DisputeDetailsFormNamesEnum.SHIPPER_WEBSITE).value;
    dispute.wniInspectorSicCd = inspectorForm.get(DisputeDetailsFormNamesEnum.INSPECTOR_SIC_CODE).value;
    dispute.wniAreaCd = inspectorForm.get(DisputeDetailsFormNamesEnum.INSPECTOR_AREA).value;
    dispute.wniInspectorEmployeeId = inspectorForm.get(DisputeDetailsFormNamesEnum.INSPECTOR_EMPLOYEE).value;

    const wniInspectorEmployeeDtl = new InterfaceEmployee();
    dispute.wniInspectorEmployeeDtl = {
      ...wniInspectorEmployeeDtl,
      employeeId: inspectorForm.get(DisputeDetailsFormNamesEnum.INSPECTOR_EMPLOYEE).value,
      firstName: inspectorForm.get(DisputeDetailsFormNamesEnum.INSPECTOR_FIRST_NAME).value,
      middleName: inspectorForm.get(DisputeDetailsFormNamesEnum.INSPECTOR_MIDDLE_NAME).value,
      lastName: inspectorForm.get(DisputeDetailsFormNamesEnum.INSPECTOR_LAST_NAME).value,
      jobDescription: inspectorForm.get(DisputeDetailsFormNamesEnum.INSPECTOR_JOB_DESCRIPTION).value,
      domicileSicCd: inspectorForm.get(DisputeDetailsFormNamesEnum.INSPECTOR_DOMICILE_SIC).value,
      supervisorEmployeeId: inspectorForm.get(DisputeDetailsFormNamesEnum.INSPECTOR_SUP_EMPLOYEE_ID).value,
      businessPhoneTxt: inspectorForm.get(DisputeDetailsFormNamesEnum.INSPECTOR_PHONE).value,
      auditInfo: this.getDocumentAuditInfo(),
    };

    dispute.onBehalfOfSameAsClaimantInd = false;
    return dispute;
  }

  createUpdateDisputeRqst(): UpdateDisputeRqst {
    return super.createUpdateDisputeRqst();
  }
}
