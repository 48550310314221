import { Party } from '@xpo-ltl/sdk-disputes';
import {
  XrtAttributeFilter,
  XrtDisputePartySearchFilter,
  XrtDisputePartySearchRqst,
  XrtSearchQueryHeader,
} from '@xpo-ltl/sdk-elasticsearch';

export class DisputePartySearchHelper {
  static getRequestForXrtDisputePartySearch(party: Party, pageNumber: number): XrtDisputePartySearchRqst {
    const request = new XrtDisputePartySearchRqst();
    const queryHeader = new XrtSearchQueryHeader();
    queryHeader.page = pageNumber;
    let searchFilter = new XrtDisputePartySearchFilter();
    searchFilter = this.addNameFilter(searchFilter, party);
    searchFilter = this.addAddressFilter(searchFilter, party);
    searchFilter = this.addCityFilter(searchFilter, party);
    searchFilter = this.addStateFilter(searchFilter, party);
    searchFilter = this.addPostalFilter(searchFilter, party);
    searchFilter = this.addCountryFilter(searchFilter, party);
    request.header = queryHeader;
    request.filter = searchFilter;
    return request;
  }

  static addNameFilter(filter: XrtDisputePartySearchFilter, party: Party): XrtDisputePartySearchFilter {
    if (party && party.name1) {
      const nameFilter = new XrtAttributeFilter();
      nameFilter.query = party.name1;
      filter.name1 = nameFilter;
    }
    return filter;
  }

  static addAddressFilter(filter: XrtDisputePartySearchFilter, party: Party): XrtDisputePartySearchFilter {
    if (party && party.addr1) {
      const addressFilter = new XrtAttributeFilter();
      addressFilter.query = party.addr1;
      filter.addr1 = addressFilter;
    }
    return filter;
  }

  static addCityFilter(filter: XrtDisputePartySearchFilter, party: Party): XrtDisputePartySearchFilter {
    if (party && party.cityName) {
      const cityFilter = new XrtAttributeFilter();
      cityFilter.query = party.cityName;
      filter.cityName = cityFilter;
    }
    return filter;
  }

  static addStateFilter(filter: XrtDisputePartySearchFilter, party: Party): XrtDisputePartySearchFilter {
    if (party && party.stateCd) {
      const stateFilter = new XrtAttributeFilter();
      stateFilter.query = party.stateCd;
      filter.stateCd = stateFilter;
    }
    return filter;
  }

  static addPostalFilter(filter: XrtDisputePartySearchFilter, party: Party): XrtDisputePartySearchFilter {
    if (party && party.postalCd) {
      const postalFilter = new XrtAttributeFilter();
      postalFilter.query = party.postalCd;
      filter.postalCd = postalFilter;
    }
    return filter;
  }

  static addCountryFilter(filter: XrtDisputePartySearchFilter, party: Party): XrtDisputePartySearchFilter {
    if (party && party.countryCd) {
      const countryFilter = new XrtAttributeFilter();
      countryFilter.query = party.countryCd;
      filter.countryCd = countryFilter;
    }
    return filter;
  }
}
