import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertWrapperComponent } from './alert-wrapper/alert-wrapper.component';
import { DialogTemplateInjectorDirective } from './dialog-template-injector.directive';
import { XpoDialogManagerService } from './xpo-dialog-manager.service';
import { MaterialModule } from '../material.module';

@NgModule({
  imports: [CommonModule, MaterialModule],
  providers: [XpoDialogManagerService],
  declarations: [AlertWrapperComponent, DialogTemplateInjectorDirective],
  entryComponents: [AlertWrapperComponent],
  exports: [AlertWrapperComponent],
})
export class XpoDialogsModule {}
