import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule   } from '@angular/material/legacy-tooltip';
// import { XpoDirectivesModule, XpoValidatorsModule } from '@xpo-ltl/angular-utils';
import { XpoDirectivesModule } from '../../../../src/app/utils/angular-utils/directives';
import { XpoValidatorsModule } from '../../../../src/app/utils/angular-utils/validators/xpo-validators.module';
import { DocTypePipe } from './pipes/doc-type.pipe';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { AngularSplitModule } from 'angular-split';
import { NgxEditorModule } from 'ngx-editor';
import { NgxFileHelpersModule } from 'ngx-file-helpers';
import { NgxPrintModule } from 'ngx-print';
import { DirectivesModule } from './directives/directives.module';
import { EmailEntryComponent } from './emails/email-entry/email-entry.component';
import { EmailsDetailComponent } from './emails/emails-detail/emails-detail.component';
import { EmailsComponent } from './emails/emails.component';
import { NotesEmailsComponent } from './notes-emails.component';
import { NotesDetailComponent } from './notes/notes-detail/notes-detail.component';
import { NotesEntryComponent } from './notes/notes-entry/notes-entry.component';
import { NotesComponent } from './notes/notes.component';
import { AsWordsPipe } from './pipes/as-words.pipe';
import { CurrencyCdPipe } from './pipes/currency-cd.pipe';
import { DocumentSearchPipe } from './pipes/document-search.pipe';
import { HumanizePipe } from './pipes/humanize.pipe';
import { NotesEmailsService } from './services/notes-emails.service';
import { UserNotesEmailsService } from './services/user.service';

@NgModule({
  imports: [
    NgxEditorModule,
    XpoDirectivesModule,
    XpoValidatorsModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    AngularSplitModule,
    MatIconModule,
    CommonModule,
    MatTabsModule,
    MatInputModule,
    XpoCardModule,
    XpoButtonModule,
    MatTooltipModule,
    MatMenuModule,
    NgxFileHelpersModule,
    DirectivesModule,
    NgxPrintModule,
  ],
  declarations: [
    NotesEmailsComponent,
    NotesComponent,
    NotesEntryComponent,
    NotesDetailComponent,
    EmailsComponent,
    EmailEntryComponent,
    EmailsDetailComponent,
    AsWordsPipe,
    HumanizePipe,
  ],
  exports: [NotesEmailsComponent, EmailEntryComponent],
  providers: [DocumentSearchPipe, CurrencyCdPipe, CurrencyPipe, DocTypePipe, UserNotesEmailsService, HumanizePipe],
})
export class NotesEmailsModule {}
