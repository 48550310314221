import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { FormatValidationService } from '@xpo-ltl/common-services';
import { ActionCd, DisputeEmailTemplateTypeCd, DisputeTypeCd, EmailDirectionTypeCd } from '@xpo-ltl/sdk-common';
import { EmailInteraction, GetDisputeResp } from '@xpo-ltl/sdk-disputes';
import {
  GetEmployeeDetailsByEmpIdPath,
  GetEmployeeDetailsByEmpIdQuery,
  GetEmployeeDetailsByEmpIdResp,
  HumanResourceApiService,
} from '@xpo-ltl/sdk-humanresource';

import * as _ from 'lodash';
import { filter, takeUntil } from 'rxjs/operators';

import { NotesEmailsService } from '../../../../../../lib/src/public_api';
import { FormUtils } from '../../../../classes/form-utils.class';
import { DeclinationEmailDialogFormNamesEnum, DialogResponse, EmailEntryFormNamesEnum } from '../../../../enums';
import { AmendEmailDialogFormNamesEnum } from '../../../../enums/form-control-names/amend-email-dialog-form-names.enum';
import { AmendExplanationDialogFormNamesEnum } from '../../../../enums/form-control-names/amend-explanation-dialog-form-names.enum';
import { ApprobationEmailDialogFormNamesEnum } from '../../../../enums/form-control-names/approbation-email-dialog-form-names.enum';
import { DialogResponseData } from '../../../../interfaces/dialog-response-data.interface';
import { DisputeApprovalDecision } from '../../../../interfaces/disputes/dispute-approval-decision.interface';
import { EmailConfig } from '../../../../interfaces/email-config.interface';
import { HumanizePipe } from '../../../../pipes/humanize.pipe';
import { DisputesDataService } from '../../../../services/disputes/disputes-data.service';
import { EmailTemplatingService } from '../../../../services/disputes/email-templating.service';
import { UserService } from '../../../../services/users/user.service';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss'],
})
export class EmailDialogComponent implements OnInit, AfterViewInit {
  unSubscriber = new Unsubscriber();
  private _emailDialogFormLabelsEnum;
  private _emailDialogFormNamesEnum;
  private _emailDialogFormBuilder;
  decision: DisputeApprovalDecision;
  templates: any[];
  private humanizePipe = new HumanizePipe();

  get title() {
    return this._emailDialogFormLabelsEnum.TITLE;
  }

  get emailDialogFormLabelsEnum() {
    return this._emailDialogFormLabelsEnum;
  }
  formGroup: FormGroup;
  emailConfig: EmailConfig;
  dispute: GetDisputeResp;
  disputeWatchers: string = '';

  get emailFormGroup() {
    return this.formGroup.get(this._emailDialogFormNamesEnum.EMAIL);
  }

  constructor(
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<EmailDialogComponent>,
    protected userService: UserService,
    protected formatValidationService: FormatValidationService,
    private disputesDataService: DisputesDataService,
    private notesEmailService: NotesEmailsService,
    protected humanResourceApiService: HumanResourceApiService,
    protected emailTemplatingService: EmailTemplatingService
  ) {
    this._emailDialogFormLabelsEnum = data.enums.emailDialogFormLabelsEnum;
    this._emailDialogFormNamesEnum = data.enums.emailDialogFormNamesEnum;
    this._emailDialogFormBuilder = data.form.formBuilder;
  }

  ngOnInit() {
    this.formInit();
    this.disputeApprovalDecision();
    this.disputeInit();
    this.completeEmailConfig();
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  disputeApprovalDecision() {
    this.disputesDataService.approvalDecision$
      .pipe(takeUntil(this.unSubscriber.done$))
      .subscribe((decision) => (this.decision = decision));
  }

  disputeInit() {
    this.disputesDataService.dispute$
      .pipe(takeUntil(this.unSubscriber.done$))
      .pipe(filter((dispute) => !!dispute))
      .subscribe((dispute) => {
        this.dispute = dispute;
        this.filterEmailTemplates(this._emailDialogFormNamesEnum.FORM_NAME);
        _.forEach(dispute.employeeNotify, (employee) => {
          const empId = new GetEmployeeDetailsByEmpIdPath();
          empId.employeeId = employee.employeeDtl.employeeId;
          const query = new GetEmployeeDetailsByEmpIdQuery();
          this.humanResourceApiService
            .getEmployeeDetailsByEmpId(empId, query)
            .subscribe((value: GetEmployeeDetailsByEmpIdResp) => {
              if (value.employee.emailAddress) {
                this.disputeWatchers += value.employee.emailAddress + ';';
              }
            });
        });
      });
  }

  /**
   * Form creation
   */
  formInit() {
    if (this._emailDialogFormBuilder) {
      this.formGroup = this._emailDialogFormBuilder.create(this.fb, this.formatValidationService);
    }
  }

  completeEmailConfig() {
    let subject = '';
    let recipient = '';
    if (this.dispute) {
      subject = this.createSubject(this.dispute.dispute.typeCd);
      recipient = this.dispute && this.dispute.claimantContact ? this.dispute.claimantContact.emailAddress : '';
    }
    const cc = '';
    this.emailConfig = {
      subject,
      recipient,
      cc,
      // email: { message: 'Hello' },
    };
  }
  createSubject(disputeType): string {
    let subject = `${this.dispute.dispute.disputeId} -`;
    const disputeTypeCdPiped = this.humanizePipe.transform(this.dispute.dispute.typeCd);
    switch (disputeType) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        subject = subject.concat(`  ${disputeTypeCdPiped} - ${this.decision.decisionReasonCd}`);
        break;
      case DisputeTypeCd.INSPECTION_APPEALS:
        subject = subject.concat(` Inspection Appeals - ${this.decision.decisionReasonCd}`);
        break;
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        subject = subject.concat(` Dispute Resolution Process - ${this.decision.decisionReasonCd}`);
        break;
      default:
        subject = this.dispute.dispute.disputeId;
        break;
    }
    return subject;
  }
  skipEmail() {
    const dialogResponseData: DialogResponseData<any> = {
      response: DialogResponse.SKIP,
    };
    this.dialogRef.close(dialogResponseData);
  }

  sendEmail() {
    if (this.formGroup.valid) {
      const email = new EmailInteraction();
      email.subject = this.emailFormGroup.get(EmailEntryFormNamesEnum.SUBJECT).value;
      email.senderEmailAddress = this.userService.currentUser.emailAddress || null;
      email.readInd = false;
      email.draftEmailInd = false;
      email.recipientEmailAddress = this.emailFormGroup.get(EmailEntryFormNamesEnum.RECIPIENTS).value;
      email.ccEmailAddress = this.emailFormGroup.get(EmailEntryFormNamesEnum.CC).value;
      email.message = this.emailFormGroup.get(EmailEntryFormNamesEnum.EMAIL_CONTENT).value;
      email.templateTypeCd = undefined;
      email.listActionCd = ActionCd.ADD;
      email.directionTypeCd = EmailDirectionTypeCd.OUTBOUND;
      email.emailAttach = [];

      const dialogResponseData: DialogResponseData<EmailInteraction> = {
        response: DialogResponse.SEND,
        data: email,
      };
      this.dialogRef.close(dialogResponseData);
    } else {
      FormUtils.markAsTouchedAndDirty(this.formGroup);
    }
  }

  cancel() {
    this.dialogRef.close(DialogResponse.CLOSE);
  }
  filterEmailTemplates(formNAme) {
    switch (formNAme) {
      case ApprobationEmailDialogFormNamesEnum.FORM_NAME:
        this.templates = this.notesEmailService.emailTemplateTypes.filter((emailTemplate: any) => {
          return (
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.BLANK_TEMPLATE ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.OCC_APPROVAL
          );
        });
        this.templates = this.compileTemplates(this.templates);
        break;
      case DeclinationEmailDialogFormNamesEnum.FORM_NAME:
        this.templates = this.notesEmailService.emailTemplateTypes.filter((emailTemplate: any) => {
          return (
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.BLANK_TEMPLATE ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.DECLINE_PER_ACCESSORIAL_CHARGE ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.DECLINE_PER_DUPLICATE_CLAIM ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.DECLINE_PER_INSPECTION ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.DECLINE_PER_INVALID_PRO ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.DECLINE_PER_NO_PAYMENT_RECEIVED ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.DECLINE_PER_PREVIOUSLY_REFUNDED ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.DECLINE_PER_PRICING_AGREEMENT ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.DECLINE_PER_REWEIGH ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.DECLINE_PER_STATUTE_OF_LIMITATION ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.DECLINE_PER_TARIFF_OR_CONTRACT ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.DECLINE_PER_CLASS ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.DECLINE_PER_UNABLE_TO_PROCESS_CLAIM
          );
        });
        this.templates = this.compileTemplates(this.templates);
        break;
      case AmendExplanationDialogFormNamesEnum.FORM_GROUP_NAME:
        // TODO - DEFINE WHICH TEMPLATES WE WANT TO SEE WHEN AMMENDING A DISPUTE LSS-1041
        break;
      case AmendEmailDialogFormNamesEnum.FORM_NAME:
        this.templates = this.notesEmailService.emailTemplateTypes.filter((emailTemplate: any) => {
          return (
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.BLANK_TEMPLATE ||
            emailTemplate.typeCd === DisputeEmailTemplateTypeCd.OCC_AMENDMENT
          );
        });
        this.templates = this.compileTemplates(this.templates);
        break;
      default:
        this.templates = this.compileTemplates(this.notesEmailService.emailTemplateTypes);
        break;
    }
  }

  private compileTemplates(templates: any[]) {
    return this.emailTemplatingService.compileTemplateList(templates);
  }
}
