import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class WindowService {
  private window;

  constructor() {
    this.window = window;
  }

  getHostname(): string {
    return window.location.hostname;
  }

  getProtocol(): string {
    return window.location.protocol;
  }

  getPort(): string {
    return window.location.port;
  }

  getCurrentPath(): string {
    return window.location.pathname;
  }
  getCurrentQueryParams(): Params {
    let params: Params = undefined;
    let query = window.location.search;
    if (query && query.length > 1) {
      if (query.startsWith('?')) {
        query = query.substring(1);
      }
      params = {};
      const queryArray = query.split('&');
      if (queryArray && queryArray.length > 0) {
        queryArray.forEach(queryParamElement => {
          const [key, value] = queryParamElement.split('=');
          params[key] = value;
        });
      }
    }
    return params;
  }

  private _appendUrlParts(part1: string, part2: string): string {
    if (!part1) {
      return !!part2 ? part2 : '';
    }
    if (!part2) {
      return !!part1 ? part1 : '';
    }

    const conditionedPart1 = `${part1}${part1.endsWith('/') ? '' : '/'}`;
    const conditionedPart2 = `${part2.startsWith('/') ? part2.substring(1) : part2}`;
    return `${conditionedPart1}${conditionedPart2}`;
  }
  public appendUrlParts(...parts: string[]): string {
    let stitched = '';
    if (parts && parts.length > 0) {
      parts.forEach((part: string) => {
        stitched = this._appendUrlParts(stitched, part);
      });
    }
    return stitched;
  }

  public openWindow(url: string, target?: string): any {
    if (url) {
      return this.window.open(url, target || '_blank');
    }
  }

  public generateDownloadFile(mimeType: string, data: string | Uint8Array, fileName: string) {
    const blob = new Blob([data], { type: mimeType });

    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.click();
      a.remove();
    }
  }
}
