import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { detect } from 'detect-browser';

@Component({
  selector: 'xpo-utils-version-banner',
  templateUrl: './version-banner.component.html',
  styleUrls: ['./version-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VersionBannerComponent implements OnInit {
  public browserInfo;

  constructor(private configManagerService: ConfigManagerService) {}

  ngOnInit(): void {
    this.browserInfo = detect();
  }

  public get buildVersion(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  }
}
