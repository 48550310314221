import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
// import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { LegacySimpleSnackBar as SimpleSnackBar, MatLegacySnackBarRef as MatSnackBarRef, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { NotificationMessage, NotificationService, NotificationTypeEnum } from '@xpo-ltl/data-api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [{ provide: ANIMATION_MODULE_TYPE, useValue: 'BrowserAnimations' }],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit, OnDestroy, AfterViewInit {
  message: string;
  messageDisplay: string;
  show: boolean;

  @Input()
  showProgressSpinner = true;

  private unsubscribe = new Subject<void>();
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  constructor(private notificationService: NotificationService, private zone: NgZone, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.notificationService
      .getSubscriber()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((message: NotificationMessage) => {
        try {
          this.zone.run(() => {
            if (message.type !== NotificationTypeEnum.ShowSnackbar) {
              this.message = message.text;
              setTimeout(() => {
                this.show = message.type === NotificationTypeEnum.ShowOverlay;
              });
            } else {
              this.snackBarRef = this.snackBar.open(
                message.text,
                message.snackbarConfig != null &&
                message.snackbarConfig.actionHandler != null &&
                message.snackbarConfig.actionHandler.actionLabel() != null
                  ? message.snackbarConfig.actionHandler.actionLabel()
                  : null,
                {
                  duration: message.snackbarConfig.durationInMillis,
                }
              );
              if (message.snackbarConfig != null && message.snackbarConfig.actionHandler != null) {
                if (message.snackbarConfig.actionHandler.onAction != null) {
                  this.snackBarRef.onAction().subscribe(message.snackbarConfig.actionHandler.onAction);
                }
                if (message.snackbarConfig.actionHandler.afterOpened != null) {
                  this.snackBarRef.afterOpened().subscribe(message.snackbarConfig.actionHandler.afterOpened);
                }
                if (message.snackbarConfig.actionHandler.afterDismissed != null) {
                  this.snackBarRef.afterDismissed().subscribe(message.snackbarConfig.actionHandler.afterDismissed);
                }
              }
            }
          });
        } catch (error) {
          // todo: log
        }
      });
  }

  ngAfterViewInit() {
    this.messageDisplay = this.message;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
