import { DisputeRequestReasonCd } from '@xpo-ltl/sdk-common';

export class DisputeRequestReasonHelper {
  /**
   * Request Reason for Claims Integration
   * *DisputeRequestReasonCd.DRP_CARGO_LOSS_DAMAGE_DISPUTE
   */
  static isValidForClaimIntegration(requestReasonCd: DisputeRequestReasonCd): boolean {
    const requestReasonsForClaimsIntegration = [DisputeRequestReasonCd.DRP_CARGO_LOSS_DAMAGE_DISPUTE];
    return requestReasonsForClaimsIntegration.includes(requestReasonCd);
  }

  /**
   * Request reason is displayed in the drop down
   */
  static isDRPDisplayedRequestReason(requestReasonCd: DisputeRequestReasonCd, canEdit: boolean): boolean {
    const retiredRequestReasonCodes = [
      DisputeRequestReasonCd.DRP_CUSTOMER_SATISFACTION,
      DisputeRequestReasonCd.DRP_CUSTOMER_NON_COMPLIANCE_NON_REVENUE_ADJUSTMENT,
      DisputeRequestReasonCd.DRPEDI_NON_REVENUE_ADJUSTMENT,
      DisputeRequestReasonCd.DRP_CLAIM_DISPUTE,
    ];

    if (requestReasonCd.startsWith('DRP')) {
      if (canEdit) {
        return !retiredRequestReasonCodes.includes(requestReasonCd);
      } else {
        return true;
      }
    }
    return false;
  }
}
