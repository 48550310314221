import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

export function xpoMinDateValidator(date: Date, alias?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const errorKey: string = alias || 'xpoMinDate';

    if (control.value && date) {
      const controlDate: Date = new Date(control.value);

      return controlDate < date ? { [errorKey]: { value: date, actualValue: control.value } } : null;
    }
  };
}
function myFunc(param) {}

export function xpoMinDateAsyncValidator(date$: BehaviorSubject<Date>, alias?: string): AsyncValidatorFn {
  return (control: AbstractControl): Observable<{ [key: string]: any }> => {
    return Observable.create(observer => {
      observer.next(xpoMinDateValidator(date$.getValue(), alias)(control));
    });
  };
}

@Directive({
  selector: '[xpoMinDate]',
  providers: [{ provide: NG_VALIDATORS, useExisting: XpoMinDateValidatorDirective, multi: true }],
})
export class XpoMinDateValidatorDirective implements Validator {
  @Input() date: Date;

  validate(control: AbstractControl): { [key: string]: any } {
    return xpoMinDateValidator(this.date)(control);
  }
}
