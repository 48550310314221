import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { DisputesApiService, GetDisputeResp } from '@xpo-ltl/sdk-disputes';

import { Observable, of } from 'rxjs';

import { RegistrationFactoryService } from '../core/services/registration-factory.service';
import { DisputesDataService } from '../services/disputes/disputes-data.service';
import { DisputeEditModeHelperService } from '../services/disputes/disputes-edit-mode-helper.service';
import { DisputesFilingService } from '../services/disputes/disputes-filing.service';
import { UserService } from '../services/users/user.service';
@Injectable()
export class DisputeResolver implements Resolve<GetDisputeResp> {
  constructor(
    private disputeApiService: DisputesApiService,
    private registrationServiceFactory: RegistrationFactoryService,
    private disputesDataService: DisputesDataService,
    private disputesFilingService: DisputesFilingService,
    private disputeEditModeHelperService: DisputeEditModeHelperService,
    private userService: UserService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return of(true);
  }
}
