import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { DisputeStatusCd } from '@xpo-ltl/sdk-common';
import { Dispute } from '@xpo-ltl/sdk-disputes';
import { DisputeEditModesEnum } from '../../enums';

@Injectable({ providedIn: 'root' })
export class DisputeEditModeHelperService {
  constructor() {}

  getEditMode(data: Data): DisputeEditModesEnum {
    const viewMode = data.hasOwnProperty('viewMode') ? data.viewMode : undefined;

    if (!viewMode) {
      throw new Error('undefined view mode');
    }

    return viewMode;
  }

  getEditModeByDisputeStatus(data: Data, dispute: Dispute): DisputeEditModesEnum {
    let viewMode = this.getEditMode(data);

    if (viewMode === DisputeEditModesEnum.VIEW_DISPUTE && dispute.statusCd === DisputeStatusCd.DRAFT) {
      viewMode = DisputeEditModesEnum.EDIT_DISPUTE;
    }

    return viewMode;
  }
}
