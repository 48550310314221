export enum DisputeDashboardFieldLabelsEnum {
  // COMMON
  DISPUTE_ID = 'Dispute #',
  DISPUTE_STATUS = 'Dispute Status',
  DISPUTE_TYPE = 'Dispute Type',
  TOTAL_PRO_COUNT = 'Total PRO Count',
  ASSIGNED_TO = 'Assigned To',
  INDICATORS = 'Indicators',
  CUSTOMER = 'Customer',
  CURRENCY = 'Currency',
  TOTAL_INVOICE_AMOUNT = 'Total Invoice Amount (at Dispute Creation)',
  TOTAL_REQUESTED_AMOUNT = 'Total Requested Adjusted Amount',
  CREATED_AT = 'Created At',
  RECIEVED_DATE = 'Received Date',
  LAST_UPDATED_DATE = 'Last Updated Date',
  ACTIONS = 'Actions',
  NOT_ELIGIBLE = 'Not Eligible',
  POTENTIAL_DUPLICATE = 'Potential Duplicate',
  FILED_BY_EMPL_NAME = 'Filed By',
  // OCC SECTION
  OCC_CLAIMANT_NAME = 'Claimant Name',
  OCC_ON_BEHALF_OF_NAME = 'On Behalf Of Name',
  // WNI SECTION
  WNI_AREA = 'Area',
  WNI_INSPECTOR_NAME = 'Inspector Name',
  WNI_INSPECTOR_SIC = 'Inspector SIC',
  // DRP SECTION
  DRP_TYPE = 'DRP Type',
  DRP_DEBTOR_NAME = 'Debtor Name',
  DRP_AE_NAE_NAME = 'AE/NAE',
  DRP_AUTHORIZED_APPROVER = 'First Level Approver',
  // MRRA SECTION
  MRRA_CUSTOMER_MAD_CODE = 'Customer MAD Code',
  MRRA_TOTAL_WHAT_IF_AMOUNT = 'Total What-If Amount',
  TOTAL_CURRENT_INVOICE_AMOUNT = 'Total Current Invoice Amount',
}
