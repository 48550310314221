import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Router } from '@angular/router';
import { ConditioningService } from '@xpo-ltl/common-services';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import {
  ElasticSearchApiService,
  XrtAttributeFilter,
  XrtClaimSearchFilter,
  XrtClaimSearchRqst,
} from '@xpo-ltl/sdk-elasticsearch';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigManagerProperties, ProNumberMaxLengthsEnum, RouterUriComponents } from '../../../../../enums';

@Component({
  selector: 'app-related-disputes',
  templateUrl: './related-disputes.component.html',
  styleUrls: ['./related-disputes.component.scss'],
})
export class RelatedDisputesComponent implements OnInit {
  relatedDisputesSubject = new BehaviorSubject<any[]>([]);
  relatedDisputes$ = this.relatedDisputesSubject.asObservable();
  relatedClaimsSubject = new BehaviorSubject<any[]>([]);
  relatedClaims$ = this.relatedClaimsSubject.asObservable();
  proNumber: string;

  get relatedDisputesCount$(): Observable<number> {
    if (!this.relatedDisputes$) {
      return of(0);
    }
    return this.relatedDisputes$.pipe(map((disputes) => disputes.length));
  }

  get relatedClaimsCount$(): Observable<number> {
    if (!this.relatedClaims$) {
      return of(0);
    }
    return this.relatedClaims$.pipe(map((claims) => claims.length));
  }

  displayedColumns: string[] = [
    'disputeNum',
    'disputeType',
    'disputeStatus',
    'assignedTo',
    'invoiceAmount',
    'reqAdjAmt',
    'lastUpdate',
    'createdAt',
  ];

  displayedColumnsClaims: string[] = [
    'claimId',
    'claimType',
    'internalStatus',
    'examinerName',
    'claimedAmount',
    'approvedAmount',
    'totalPaymentAmount',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public relatedDisputes: any,
    protected configManagerService: ConfigManagerService,
    private elasticSearchApiService: ElasticSearchApiService,
    private conditioningService: ConditioningService,
    private currencyPipe: CurrencyPipe,
    protected router: Router
  ) {}

  ngOnInit() {
    this.proNumber = this.relatedDisputes.row.proNbr;
    this.getRelatedClaims();
    this.getRelatedDisputes();
  }

  onRelatedDisputeClick(element: any) {
    const url = `${RouterUriComponents.DISPUTES_VIEW_DISPUTE}/${element.disputeNum}/${element.disputeWorkflowId}`;
    window.open(url, '_blank');
  }

  onRelatedClaimClick(element: any) {
    const appUrl = this.configManagerService.getSetting<string>(ConfigManagerProperties.appUrl);
    const claimsAppUrl = this.configManagerService.getSetting<string>(ConfigManagerProperties.claimsAppUrl);
    const url = `${appUrl}${claimsAppUrl}/${element.claimId}`;
    window.open(url, '_blank');
  }

  getRelatedDisputes(): void {
    if (!this.proNumber) {
      return;
    }
    this.relatedDisputesSubject.next(this.relatedDisputes.relDispData);
  }

  getRelatedClaims(): void {
    if (!this.proNumber) {
      return;
    }
    const request = new XrtClaimSearchRqst();
    request.filter = new XrtClaimSearchFilter();
    request.filter.proNbr = new XrtAttributeFilter();
    request.filter.proNbr.equals = this.conditioningService.conditionProNumber(
      this.proNumber,
      ProNumberMaxLengthsEnum.INPUT
    );

    this.elasticSearchApiService.xrtClaimSearch(request).subscribe((retval) => {
      if (!retval || !retval.result) {
        return;
      }
      const relatedClaimsArray = retval.result.map((row) => {
        const claim = {
          claimId: row.claimId,
          claimType: row.typeCd,
          internalStatus: row.internalStatusCd,
          filingDate: row.filingDate,
          examinerName: row.examinedBy,
          claimedAmount: this.currencyPipe.transform(row.claimedAmount),
          approvedAmount: this.currencyPipe.transform(row.approvedAmount),
          totalPaymentAmount: this.currencyPipe.transform(row.totalPaymentAmount),
        };
        return claim;
      });
      this.relatedClaimsSubject.next(relatedClaimsArray);
    });
  }
}
