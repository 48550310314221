import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
// import { LoginService } from '@xpo-ltl/login';
import { DisputeTypeCd, GlobalSearchDetailCd, User } from '@xpo-ltl/sdk-common';
import { XpoLtlAppSwitcherService } from '@xpo-ltl/ngx-ltl';
import { XpoLtlRoleSwitcherService, XpoLtlRoleSwitcherData, XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';

import { XpoAccountPopoverConfig } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { XpoAppSwitcherApplication } from '@xpo-ltl/ngx-ltl-core/app-switcher-popover';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';

import { BehaviorSubject, Observable } from 'rxjs';
import { delay, filter, map, retryWhen, take, takeUntil } from 'rxjs/operators';

import { ConfigManagerProperties, RouterUriComponents, SvgIconsEnums } from './enums';
import { LoadingManagerService } from './services/disputes/loading-manager.service';

import { AccountUrls } from './enums/account-urls.enum';
import { AppConstantsService } from './services/app-constants.service';
import { UserService } from './services/users/user.service';
import { GlobalSearchOptions } from '@xpo-ltl/ngx-ltl-global-search';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AppComponent implements OnInit, OnDestroy {
  unsubscriber = new Unsubscriber();
  title: string;
  build: string;
  isClosedByDefault = true;
  isProduction: boolean;
  isShipDetail: boolean;
  // apps$: Observable<XpoAppSwitcherApplication[]>;
  apps: XpoAppSwitcherApplication[] = [];
  accountPopoverConfig: XpoAccountPopoverConfig;
  routes: XpoShellRoute[];

  private routesSubject = new BehaviorSubject<XpoShellRoute[]>([]);
  routes$ = this.routesSubject.asObservable();

  get currentUser$() {
    return this.userService.currentUser$.pipe(map((user) => (user ? user.displayName : 'Not logged')));
  }

  get showFinishedLoading$() {
    return this.loadingManagerService.showFinishingLoading$;
  }

  get isDrawerOpen$(): Observable<boolean> {
    return this.constants.isDrawerOpen$;
  }

  static readonly globalSearchOptions: GlobalSearchOptions = {
    globalSearchResultTypes: [
      { detailCd: GlobalSearchDetailCd.CLAIM_RESULT },
      { detailCd: GlobalSearchDetailCd.DISPUTE_RESULT }
    ],
  };
  
  // ATTENTION: don't delete the `constants` property, we are using it to load the countries
  constructor(
    private configManagerService: ConfigManagerService,
    private userService: UserService,
    private constants: AppConstantsService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private loadingManagerService: LoadingManagerService,
    private appSwitcherService: XpoLtlAppSwitcherService,
    // private loginService: LoginService,
    private authService: XpoLtlAuthenticationService,
    private loginService: XpoLtlLoggedInUserService,
    private router: Router
  ) {
    this.title = configManagerService.getSetting<string>(ConfigManagerProperties.appName);
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.isProduction = !!configManagerService.getSetting<string>(ConfigManagerProperties.production);
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
      this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {
    });
    this.addDisputesIcons();

    

    this.loggedInUserFunc();
    this.loginService.userLoggedIn$.subscribe(() => {
      this.loggedInUserFunc();
    });
  }

  private loggedInUserFunc() {
    this.loginService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            delay(1000),
            take(5)
          )
        )
      )
      .subscribe(
        (user: User) => {
          if (user) {
            this.populateAccountPopover(user);
          }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  };

  private getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  showUserDetail() {}

  ngOnInit(): void {
    this.userService.permissions$.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      this.populateAppSwitcher();
      this.populateShellRoutes();
    });
    this.isShipDetail = window.location.href.includes(RouterUriComponents.SHIPMENT_DETAIL);
  }

  isDrawerOpenChange(isOpen) {
    this.constants.isDrawerOpen = isOpen;
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  addDisputesIcons() {
    this.iconRegistry.addSvgIcon(
      SvgIconsEnums.NOT_ELIGIBLE,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/not-eligible.svg')
    );
    this.iconRegistry.addSvgIcon(
      SvgIconsEnums.POSSIBLE_DUPLICATE,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/possible-duplicate.svg')
    );
    this.iconRegistry.addSvgIcon(
      SvgIconsEnums.XPO_PUBLISH,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/xpo-icons/upload.svg')
    );
    this.iconRegistry.addSvgIcon(
      SvgIconsEnums.XPO_TRASH,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/xpo-icons/delete.svg')
    );
    this.iconRegistry.addSvgIcon(
      SvgIconsEnums.FILE_CSV,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/file-csv.svg')
    );
    this.iconRegistry.addSvgIcon(
      SvgIconsEnums.FILE_EXCEL,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/file-excel.svg')
    );
  }

  private populateShellRoutes() {
    const newDisputeRoute: XpoShellRoute = {
      label: 'New Dispute',
      path: '',
      queryParamsHandling: 'merge',
      children: [
        {
          label: 'New DRP Dispute',
          path: `${RouterUriComponents.DISPUTES_CREATE_DISPUTE}/${DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS}`,
        },
        {
          label: 'New WNI Dispute',
          path: `${RouterUriComponents.DISPUTES_CREATE_DISPUTE}/${DisputeTypeCd.INSPECTION_APPEALS}`,
        },
        {
          label: 'New OCC Dispute',
          path: `${RouterUriComponents.DISPUTES_CREATE_DISPUTE}/${DisputeTypeCd.OVERCHARGE_CLAIMS}`,
        }
      ],
    };
    this.routes = [
      {
        label: 'Disputes List',
        path: RouterUriComponents.DASHBOARD,
        queryParamsHandling: 'merge',
      },
    ];

    if (this.userService.canCreateDispute()) {
      this.routes.push(newDisputeRoute);
      this.routesSubject.next(this.routes);
    }

    this.routesSubject.next(this.routes);
  }

  private populateAccountPopover(user: User): void {
    const fullName = `${user.givenName} ${user.lastName}`;

    this.accountPopoverConfig = {
      imageUri: this.getProfilePictureUrl(user.emailAddress),
      name: fullName,
      onSignOutCallback: (): void => {
        this.signOut();
      },
      links: [{ title: 'My Account', url: AccountUrls.myAccount }],
    };
  }

  private populateAppSwitcher(): void {
    // this.apps$ = this.appSwitcherService.getAppList();
    // LSS-1813: workaround while we wait LSS-1623 witch depends on SSO implementation
    this.appSwitcherService
      .getAppList()
      .pipe(
        filter((apps) => !!apps.length),
        take(1)
      )
      .subscribe((apps) => {
        this.apps = apps.map((app) => {
          let pndUrl = '';
          let imageViewerInN = '';
          switch (this.configManagerService.getSetting(ConfigManagerProperties.sysRegion)) {
            case 'PROD':
              pndUrl = 'https://pnd-ltl.firebaseapp.com/appjs/pnd';
              break;
            case 'CTSN':
              pndUrl = 'https://pnd-5.dev-ltl-xpo.com/';
              imageViewerInN = 'https://tctsi-app.ltl.xpo.com/appjs/image-viewer/image-search';
              break;
            case 'CTSS':
              pndUrl = 'https://pnd-s.dev-ltl-xpo.com/';
              break;
            case 'CTSR':
            case 'TCTSR':
              pndUrl = 'https://tctsi-pnd.firebaseapp.com/appjs/pnd/inbound-planning';
              break;
          }
          if (app.name === 'Pickup and Delivery') {
            app.url = pndUrl;
          } else if (app.name === 'Load Photo Viewer' && imageViewerInN) {
            app.url = imageViewerInN;
          }
          return app;
        });
      });
  }

  private signOut(): void {
    // Removing the local storage keys
    this.router.navigate([RouterUriComponents.DASHBOARD]).then(() => {
      this.userService.clear();
      // this.loginService.clear();
      localStorage.clear();
      location.reload();
    });
  }
}
