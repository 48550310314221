import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EmailTemplatingService } from './../../../../../../src/app/services/disputes/email-templating.service';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';

import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormUtils } from '../../classes/form-utils.class';
import {
  EmailEntryFormLabelsEnum,
  EmailEntryFormNamesEnum,
  EmailEntryMaxLengthsEnum,
  EmailSubjectPrefixesEnum,
} from '../../enums';
import { EmailsConfig } from '../../interfaces/email-config.interface';
import { Editor, Toolbar } from 'ngx-editor';

@Component({
  selector: 'xpo-email-entry',
  templateUrl: './email-entry.component.html',
  styleUrls: ['./email-entry.component.scss'],
})
export class EmailEntryComponent implements OnInit, OnChanges, OnDestroy {
  readonly EmailEntryFormNamesEnum = EmailEntryFormNamesEnum;
  readonly EmailEntryFormLabelsEnum = EmailEntryFormLabelsEnum;
  readonly EmailEntryMaxLengthsEnum = EmailEntryMaxLengthsEnum;
  private unsubscriber = new Unsubscriber();
  private templatesSubject = new BehaviorSubject<string[]>(undefined);
  templates$ = this.templatesSubject.asObservable();
  @Input()
  formGroup: FormGroup;

  @Input()
  templates: any[] = [];

  @Input()
  defaultEmailTemplate: any;

  @Input()
  config: EmailsConfig;

  @Input('editorConfig')
  editorConfigInput;

  // you can optionally pass in a list of CC email addresses
  @Input()
  set ccEmails(emails: string) {
    this.formGroup.get(EmailEntryFormNamesEnum.CC).setValue(emails);
  }
  editor: Editor;
  toolbar: Toolbar = [['bold', 'underline', 'italic'], ['text_color', 'background_color'], ['ordered_list', 'bullet_list'], ['align_left', 'align_right']];

  editorConfig = {};
  defaultEditorConfig = {
    editable: true,
    placeholder: '',
    minHeight: '100px',
    // toolbar: ['justifyLeft', 'justifyRight', 'orderedList', 'unorderedList', 'underline', 'italic', 'bold', 'color'],
  };

  get emailContentFormControl() {
    return this.formGroup.get(EmailEntryFormNamesEnum.EMAIL_CONTENT);
  }

  constructor(private templateService: EmailTemplatingService) {}

  ngOnInit() {
    this.editorConfigInit();
    this.emailInit();
    this.initSubscriptions();
    this.editor = new Editor(this.editorConfig);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDefaultTemplate();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private editorConfigInit() {
    this.editorConfig = {
      ...this.defaultEditorConfig,
      ...this.editorConfigInput,
    };
  }

  /**
   * Email completion base on config
   */
  private emailInit() {
    const prefix = this.config.subjectPrefix || '';
    const subject = this.config.subject || '';
    this.formGroup.patchValue({
      [EmailEntryFormNamesEnum.SUBJECT]: `${prefix}${subject}`,
      [EmailEntryFormNamesEnum.RECIPIENTS]: this.config.recipient || '',
      [EmailEntryFormNamesEnum.CC]: this.config.cc || '',
      [EmailEntryFormNamesEnum.TEMPLATE]: this.defaultEmailTemplate,
    });
  }

  private initSubscriptions() {
    this.formGroup
      .get(EmailEntryFormNamesEnum.TEMPLATE)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((template) => this.setEmailTemplate(template));

    this.formGroup
      .get(EmailEntryFormNamesEnum.SUBJECT)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((subject) => this.checkSubjectPrefixEditable(subject));
  }

  checkSubjectPrefixEditable(subject: string): void {
    if (!this.config.subjectPrefixEditable) {
      const subjectPrefix = this.config.subjectPrefix || '';
      if (!subjectPrefix || Object.values(EmailSubjectPrefixesEnum).some((prefix) => subject.includes(prefix))) {
        return;
      }
      if (subjectPrefix && !subject.includes(subjectPrefix)) {
        this.formGroup
          .get(EmailEntryFormNamesEnum.SUBJECT)
          .setValue(subjectPrefix, { onlySelf: true, emitEvent: false });
      }
    }
  }

  setDefaultTemplate() {
    this.formGroup.get(EmailEntryFormNamesEnum.TEMPLATE).setValue(this.defaultEmailTemplate);
  }

  replaceWhiteSpacesWithComa(emailList: string) {
    return emailList.replace(' ', ';');
  }

  clearFormFields() {
    FormUtils.untouchAllControls(this.formGroup.get(EmailEntryFormNamesEnum.SUBJECT));
    FormUtils.untouchAllControls(this.formGroup.get(EmailEntryFormNamesEnum.RECIPIENTS));
    FormUtils.untouchAllControls(this.formGroup.get(EmailEntryFormNamesEnum.CC));
    FormUtils.untouchAllControls(this.formGroup.get(EmailEntryFormNamesEnum.EMAIL_CONTENT));

    this.formGroup.get(EmailEntryFormNamesEnum.SUBJECT).setValue('');
    this.formGroup.get(EmailEntryFormNamesEnum.RECIPIENTS).setValue('');
    this.formGroup.get(EmailEntryFormNamesEnum.CC).setValue('');
    this.formGroup.get(EmailEntryFormNamesEnum.EMAIL_CONTENT).setValue('');
    this.emailInit();
  }

  setEmailTemplate(emailTemplate) {
    if (!emailTemplate || !emailTemplate.body) {
      return;
    }
    this.formGroup
      .get(EmailEntryFormNamesEnum.EMAIL_CONTENT)
      .setValue(this.templateService.compileSingleTemplate(emailTemplate.body));
  }
}
