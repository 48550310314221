import { Injectable } from '@angular/core';

import { DisputeTypeCd } from '@xpo-ltl/sdk-common';

import { DisputeFilingFormNamesEnum } from '../../enums';
import { DisputesRegistrationService } from '../../services/disputes/disputes-registration.service';
import { DrpDisputesRegistrationService } from '../../services/disputes/drp-disputes-registration.service';
import { OccDisputesRegistrationService } from '../../services/disputes/occ-disputes-registration.service';
import { WniDisputesRegistrationService } from '../../services/disputes/wni-disputes-registration.service';
import { DisputesFilingService } from './../../services/disputes/disputes-filing.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationFactoryService {
  constructor(
    public disputesFilingService: DisputesFilingService,
    public disputesRegistrationService: DisputesRegistrationService,
    public occDisputesRegistrationService: OccDisputesRegistrationService,
    public drpDisputesRegistrationService: DrpDisputesRegistrationService,
    public wniDisputesRegistrationService: WniDisputesRegistrationService
  ) {}

  get(disputeType?: DisputeTypeCd): DisputesRegistrationService {
    const type: DisputeTypeCd = disputeType || this.disputesFilingService.getDisputeType();

    switch (type) {
      case DisputeTypeCd.OVERCHARGE_CLAIMS:
        return this.occDisputesRegistrationService;
      case DisputeTypeCd.DISPUTE_RESOLUTION_PROCESS:
        return this.drpDisputesRegistrationService;
      case DisputeTypeCd.INSPECTION_APPEALS:
        return this.wniDisputesRegistrationService;
      default:
        return this.disputesRegistrationService;
    }
  }

  resetServices() {
    this.reset(this.disputesRegistrationService);
    this.reset(this.occDisputesRegistrationService);
    this.reset(this.drpDisputesRegistrationService);
    this.reset(this.wniDisputesRegistrationService);
    this.disputesRegistrationService.disputeDataService.disputeSubmissionInProgress = false;
  }

  private reset(service: DisputesRegistrationService) {
    if (service.disputeDataService.canReset) {
      if (service.getFormGroup() && service.getFormGroup().get(DisputeFilingFormNamesEnum.FORM_GROUP_NAME)) {
        service
          .getFormGroup()
          .get(DisputeFilingFormNamesEnum.FORM_GROUP_NAME)
          .get(DisputeFilingFormNamesEnum.DISPUTE_TYPE)
          .setValue('');
      }
      service.disputesFilingService.resetDisputeType(undefined);
      service.shipmentDataSource.resetRows();
      service.disputeFormGroup = service.fb.group({});
      service.disputeDataService.reset();
      service.clearFormErrorList();
    }
  }
}
