export enum DisputeDashboardFieldNamesEnum {
  // COMMON
  SELECT = 'select',
  DISPUTE_ID = 'disputeId',
  DISPUTE_STATUS = 'workflowItemStatus',
  DISPUTE_TYPE = 'typeCd',
  TOTAL_PRO_COUNT = 'totalProCount',
  ASSIGNED_TO = 'workflowItemAssignedToEmployeeName',
  INDICATORS = 'indicators',
  CUSTOMER = 'customer',
  CURRENCY = 'currencyCd',
  TOTAL_INVOICE_AMOUNT = 'totalUSDInvoiceAmountAtDisputeCreation',
  TOTAL_INVOICE_AMOUNT_USD = 'totalUSDInvoiceAmountAtDisputeCreation',
  TOTAL_INVOICE_AMOUNT_CAD = 'totalCADInvoiceAmountAtDisputeCreation',
  TOTAL_REQUESTED_AMOUNT = 'totalUSDRequestedAdjustmentAmount',
  TOTAL_REQUESTED_AMOUNT_USD = 'totalUSDRequestedAdjustmentAmount',
  TOTAL_REQUESTED_AMOUNT_CAD = 'totalCADRequestedAdjustmentAmount',
  CREATED_AT = 'disputeCreateDate',
  RECEIVED_DATE = 'disputeReceivedDate',
  LAST_UPDATED_DATE = 'disputeLastUpdateDate',
  FILED_BY_EMPL_NAME = 'filedByEmployeeName',
  ACTIONS = 'actions',
  NOT_ELIGIBLE = 'ineligibleInd',
  POTENTIAL_DUPLICATE = 'potentialDuplicateInd',
  // OCC SECTION
  OCC_CLAIMANT_NAME = 'claimantName',
  OCC_ON_BEHALF_OF_NAME = 'onBehalfOfName',
  // WNI SECTION
  WNI_AREA = 'wniInspectorArea',
  WNI_INSPECTOR_NAME = 'wniInspectorName',
  WNI_INSPECTOR_SIC = 'wniInspectorSicCd',
  // DRP SECTION
  DRP_TYPE = 'drpCategoryCd',
  DRP_DEBTOR_NAME = 'currentDebtorName',
  DRP_AE_NAE_NAME = 'drpAeNaeName',
  DRP_AUTHORIZED_APPROVER = 'authorizedApproverName',
  // MRRA SECTION
  MRRA_CUSTOMER_MAD_CODE = 'mrraCustomerMadCd',
  MRRA_TOTAL_WHAT_IF_AMOUNT = 'totalMrraWhatIfAmount',
  TOTAL_CURRENT_INVOICE_AMOUNT = 'totalCurrentInvoiceAmunt',
}
